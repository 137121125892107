import MainService from "@/services/MainService.js";
import InfGeneral from "@/components/layout/DiseñoCurricular/InformacionGeneral.vue";
import Esquema from "@/components/layout/PlanTrabajo/Esquema.vue";
import Competencias from "@/components/layout/PlanTrabajo/Competencias.vue";
import Criterios from "@/components/layout/PlanTrabajo/Criterios.vue";
import Resumen from "@/components/layout/PlanTrabajo/Resumen.vue";
import Bibliografia from "@/components/layout/PlanTrabajo/Bibliografia.vue";

window.$ = window.jQuery = require("jquery");

export default {
    name: "MallaCuricularPage",
    data() {
        let ss = new MainService();
        return {
            accion:'Crear',
            indexCriterioCompetencia: 'a',
            msg: "MallaCurricularPage",
            ss: ss,
            competencias:{},
            reporte:{},
            storeCriterios1:{},
            planTrabajo : false,
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout : 'InfGeneral'
        };
    },
    methods: {

        gerReportes(){
            this.ss.getReporte(this.materia).then(
                (result) => {
                    let response = result;
                    this.reporte = response.data;
                    console.log('REPORTE');
                    console.log(this.reporte);
                }).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                });
        },
       
        },
        
    components: {
        InfGeneral,
        Esquema,
        Competencias,
        Criterios,
        Resumen,
        Bibliografia
    },
    props: {
        materia: { default: "" },
    },
    mounted() {
        this.gerReportes();
    } 
};