import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import PlanTrabajo from "@/components/layout/PlanTrabajo/PlanTrabajo.vue";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PlanTrabajoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PlanTrabajoPage",
            ss: ss,
            ajax: {
                "url": ss.indexMateriaDocente(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idDocente = 0;
                    d.idEspecialidad = 0;
                    d.UnidadAcademica = 0;
                    }
            },
            columns: [
                { data: 'idMateria', name: 'idMateria', orderable: false, searchable: false, visible:false},
                { data: 'idCurso', name: 'idCurso', orderable: false, searchable: false, visible: false },
                { data: 'idDocente', name: 'idDocente', orderable: false, searchable: false, visible: false },
                { data: 'Dicta', name: 'Dicta', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Especialidad', name: 'Especialidad', title: 'Especialidad' },
                { data: 'Materia', name: 'Materia', title: 'Materia' },
                { data: 'Sigla', name: 'Sigla', title: 'Sigla' },
                { data: 'Docente', name: 'Docente', title: 'Docente' },
                { data: 'Curso', name: 'Curso', title: 'Curso' },
                { data: 'Dicta', name: 'Dicta', title: 'Dicta' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Academica' },
                { data: 'PeriodoGestion', name: 'PeriodoGestion', title: 'Periodo' },
                { data: 'Gestion', name: 'Gestion', title: 'Gestion' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            pTrabajo:{},
            materiaDocente:{},
            ptrabajoShow:{},
            ptrabajoShowDetalle:{},
            busquedaSaga:{},
            especialidadesSAGA:[],
            unidadAcademicas:[],
            planTrabajo : false,
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout : 'PlanTrabajo',
            auth:{},
            gestionActual:2024,
            materiaRequisitos:[]
        };
    },
    methods: {
         loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.usuario.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        goBackPlanTrabajo(){
            this.planTrabajo=false;
        },

        async showMateriaDocente(){
            await this.ss.showMateriaDocente(this.materiaDocente).then(
                (result) => {
                    let response = result.data;
                    this.materiaDocente = response[0];
                    console.log(this.materiaDocente);
                    this.showPlanTrabajo();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
         

        showPlanTrabajo(){
            this.$refs['view-PlanTrabajo'].show();
        },

        seleccionarMenu(valor){
            this.seleccion = valor
        },

        ingresarPlanTrabajo(){
            this.planTrabajo = true;
            this.$refs['view-PlanTrabajo'].hide();
        },

        async getPlanTrabajo(){
             if(this.auth.idDocente){
                let iddoc = this.auth.idDocente;
                this.ajax.data = function(d){
                    d.idDocente = iddoc;
                }
            }
            await new Promise(resolve => setTimeout(resolve, 2000));
             this.$refs['datatable-PlanTrabajo'].reloadAjax(this.ajax);
        },

        async BusquedaSaga(){
            this.isLoading=true;
            console.log(this.busquedaSaga);
            let idEspecialidad = this.busquedaSaga.idEspecialidad;
            let idUnidadAcademica = this.busquedaSaga.idUnidadAcademica;
            this.ajax.data = function(d){
                d.idEspecialidad = idEspecialidad;
                d.idUnidadAcademica = idUnidadAcademica;
            }

            this.$refs['datatable-PlanTrabajo'].reloadAjax(this.ajax); 
            this.isLoading=false;
        },

        getEspecialidades(){
            this.ss.listEspecialidad().then(
               (result) => {
                   let response = result.data;
                   console.log(response);
                   this.especialidadesSAGA = response;
               }
           ).catch(error => {
               console.log(error);
           });
        },

        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getMateriaRequisito(id) {
            this.ss.listRequisitos(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.materiaRequisitos = response.data;
                    }else{
                        this.materiaRequisitos = [];
                        console.log(this.materiaRequisitos);
                    }
                }
            ).catch(error => {
                console.log(error);
            });
        },

        draw() {
            window.$('.btn-datatable-PlanTrabajo').on('click', (evt) => {
                this.materiaDocente = {};
                this.materiaDocente.idMateria = window.$(evt.target)[0].getAttribute('idMateria');
                this.materiaDocente.idCurso = window.$(evt.target)[0].getAttribute('idCurso');
                this.materiaDocente.idDocente = window.$(evt.target)[0].getAttribute('idDocente');
                this.materiaDocente.Dicta = window.$(evt.target)[0].getAttribute('Dicta');
                console.log(this.materiaDocente);
                if(this.materiaDocente.idMateria == null){
                    this.$swal.fire(
                        'Oops...',
                        'Por favor intente nuevamente.',
                        'error'
                      )
                    return;
                }
                //this.showMateriaDocente()
                this.showMateriaDocente();
                this.getMateriaRequisito(this.materiaDocente.idMateria);
            });
        }
    },
    components: {
        dataTable,
        Loader,
        PlanTrabajo
    },
    mounted() {
        this.auth = JSON.parse(localStorage.getItem('usuario'));
        if (!this.auth) {
            this.$router.push('/Login');
        } else {
            this.getPlanTrabajo();
            this.getEspecialidades();      
            this.getUnidadAcademica();
            console.log(this.auth);
        }
    } 
};