import MainService from "@/services/MainService.js";

window.$ = window.jQuery = require("jquery");

export default {
    name: "MallaCuricularPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MallaCurricularPage",
            ss: ss,
            criterios:{},
            contenido:{},
            contenidoUD:{},
            contenidoshow:{},
            count:0,
            selectCriterioCompetencia:{},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
        };
    },
    methods: {

        seleccionMenu(){
            this.count++;
            if(this.count ==2){
                this.contenidoshow = this.criterios[this.$refs.selectCriterioCompetencia.value];
                this.getContenido(this.contenidoshow);
                this.count = 0;
                
            }
            
        },
        getCriterios(){
                this.ss.showCriteriosCompetencia(this.competenciaUD).then(
                    (result) => {
                        let response = result;
                        console.log(response.data);
                        if(response.data == null){
                            this.criterios == {};
                            this.contenido == {};
                        }
                        this.criterios = response.data;
                        this.contenidoshow =  this.criterios[0];
                        this.getContenido(this.criterios[0]);

                    }).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                    });
        },
        getContenido(item){
            this.ss.showCriteriosContenido(item).then(
                (result) => {
                    let response = result;
                    console.log(response.data);
                    if(response.data == null){
                        this.contenido = {};
                    }
                    this.contenido = response.data;
                    console.log(this.contenido);
                }).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                });
        },

        crearMateriaContenido(){
            this.indexMateriaContenido = 'a';
            this.contenido1.NumeroContenidos='';
            this.contenido1.UnidadDidactica='';
            this.accion = 'Crear';
        },

        storeMateriaContenido(){
            if(this.contenidoUD && (this.contenidoUD.NumeroContenidos && this.contenidoUD.UnidadDidactica)){
                console.log(this.contenidoUD);
                this.ss.storeCriterioContenido(this.contenidoUD).then(
                    (result) => {
                        let response = result.data;
                        console.log(response);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 2000
                            }
                        )
                        this.getContenido(this.contenidoshow);
                        this.$refs['frm-agregar-contenidoUD'].hide();
                        this.$refs['frm-detalle-contenidoUD'].hide();
                    }).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                    });  
            }
            else{
                alert('Los Campos no pueden estar vacios !');
            }

           
        },

        eliminarMateriaContenido(item){

                this.$swal.fire({
                    title: 'Eliminar Unidad Didactica ?',
                    text: "Esta accion es irreversible !",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, Eliminar !'
                  }).then((result) => {
                    if (result.isConfirmed) {
    
                        this.$swal.fire(
                            'Eliminado!',
                            'Unidad Didactica Eliminada.',
                            'success'
                          )

                          this.ss.eliminarCriterioContenido(item).then(
                            (result) => {
                                let response = result.data;
                                console.log(response);
                                this.getContenido(this.contenidoshow);
                                
                            }).catch(error => {
                                console.log(error);
                                this.isLoading=false;
                            });
    
                    }
                  })
            
        },

        agregarUD(){
            this.contenidoUD = {};
            this.contenidoUD.MateriaCriterioDesempeno_id = this.contenidoshow.id;
            console.log(this.contenidoUD);
            this.$refs['frm-agregar-contenidoUD'].show();
        },

        cancelarAgrarUD(){
            this.$refs['frm-agregar-contenidoUD'].hide();
        },

        cancelarDetalleUD(){
            this.$refs['frm-detalle-contenidoUD'].hide();
        },


        detalleUD(item){
            this.contenidoUD = item;
            console.log(this.contenidoUD);
            this.$refs['frm-detalle-contenidoUD'].show();
        }
    },
    props: {
        competenciaUD: { default: "" },
    },

    mounted() {
        this.getCriterios();
        console.log(this.competenciaUD);
    } 
};