import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import CuadernoSeguimiento from "@/components/layout/CuadernoSeguimiento/CuadernoSeguimiento.vue";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CuadernoSeguimientoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CuadernoSeguimientoPage",
            ss: ss,
            ajax: {
                "url": ss.indexMateriaDocente(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idDocente = 0;
                    d.idEspecialidad = 0;
                    d.UnidadAcademica = 0;
                    }
            },
            columns: [
                { data: 'idMateria', name: 'idMateria', orderable: false, searchable: false, visible:false},
                { data: 'idCurso', name: 'idCurso', orderable: false, searchable: false, visible: false },
                { data: 'idDocente', name: 'idDocente', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Especialidad', name: 'Especialidad', title: 'Especialidad' },
                { data: 'Materia', name: 'Materia', title: 'Materia' },
                { data: 'Sigla', name: 'Sigla', title: 'Sigla' },
                { data: 'Docente', name: 'Docente', title: 'Docente' },
                { data: 'Curso', name: 'Curso', title: 'Curso' },
                { data: 'Dicta', name: 'Dicta', orderable: false, searchable: false, visible: false },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Academica' },
                { data: 'PeriodoGestion', name: 'PeriodoGestion', title: 'Periodo' },
                { data: 'Gestion', name: 'Gestion', title: 'Gestion' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            auth:{},
            pTrabajo:{},
            materiaDocente:{},
            ptrabajoShow:{},
            ptrabajoShowDetalle:{},
            busquedaSaga:{},
            especialidadesSAGA:[],
            unidadAcademicas:[],
            planTrabajo : false,
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout : 'CuadernoSeguimiento'
        };
    },
    methods: {
         loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.usuario.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        goBackCuadernoSeguimiento(){
            this.planTrabajo=false;
        },

        showCuadernoSeguimientoDetalle(){
            this.cuaderno = {};
            console.log('ESTAMOS EN SHOW');
            this.ss.showMallaCurricular(this.showMalla).then(
                (result) => {
                    let response = result.data;
                    this.malla = response;
                    console.log(this.malla);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                });
            this.$refs['view-DiseñoCurricularDetalle'].show();
        },

        showDiseñoCurricularDetalle(){
            this.$refs['view-CuadernoDetalle'].show();
        },

        async getCuadernos(){
            

            if(this.auth.idDocente){
                let iddoc = this.auth.idDocente;
                this.ajax.data = function(d){
                    d.id = iddoc;
                }
                console.log(this.ajax);
            }

            let id = 0;
            if(this.auth.idDocente){
                id = this.auth.idDocente;
            }
            
            await new Promise(resolve => setTimeout(resolve, 9000));

            this.ss.getCuadernoSeguimiento(id).then(
                (result) => {
                    let response = result.data;
                    this.cuadernoSeguimiento = response.data;
                    console.log(this.cuadernoSeguimiento);
                    this.$refs['datatable-CuadernoSeguimiento'].reloadAjax(this.ajax);
                    this.isLoading=false;
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                });
        },

        showCuadernoSeguimiento(){
            this.CuadernoSeguimientoLayout = true;
            this.$refs['view-CuadernoDetalle'].hide();
        },




        async getPlanTrabajo(){
            if(this.auth.idDocente){
               let iddoc = this.auth.idDocente;
               this.ajax.data = function(d){
                   d.idDocente = iddoc;
               }
           }
           await new Promise(resolve => setTimeout(resolve, 2000));
            this.$refs['datatable-PlanTrabajo'].reloadAjax(this.ajax);
       },

       getEspecialidades(){
        this.ss.listEspecialidad().then(
           (result) => {
               let response = result.data;
               console.log(response);
               this.especialidadesSAGA = response;
           }
       ).catch(error => {
           console.log(error);
       });
    },

    getUnidadAcademica() {
        this.ss.listUnidadAcademica().then(
            (result) => {
                let response = result.data;
                this.unidadAcademicas = response.data;
            }
        ).catch(error => {
            console.log(error);
        });
    },

    async BusquedaSaga(){
        this.isLoading=true;
        console.log(this.busquedaSaga);
        let idEspecialidad = this.busquedaSaga.idEspecialidad;
        let idUnidadAcademica = this.busquedaSaga.idUnidadAcademica;
        this.ajax.data = function(d){
            d.idEspecialidad = idEspecialidad;
            d.idUnidadAcademica = idUnidadAcademica;
        }

        this.$refs['datatable-PlanTrabajo'].reloadAjax(this.ajax); 
        this.isLoading=false;
    },

    async showMateriaDocente(){
        this.isLoading=true;
        await this.ss.showMateriaDocente(this.materiaDocente).then(
            (result) => {
                let response = result.data;
                this.materiaDocente = response[0];
                console.log(this.materiaDocente);
                this.planTrabajo = true;
                this.isLoading=false;
            }
        ).catch(error => {
            console.log(error);
            this.isLoading=false;
        });
    },
    showPlanTrabajo(){
        
        this.$refs['view-PlanTrabajo'].hide();
    },

        draw() {
            window.$('.btn-datatable-PlanTrabajo').on('click', (evt) => {
                this.materiaDocente = {};
                this.materiaDocente.idMateria = window.$(evt.target)[0].getAttribute('idMateria');
                this.materiaDocente.idCurso = window.$(evt.target)[0].getAttribute('idCurso');
                this.materiaDocente.idDocente = window.$(evt.target)[0].getAttribute('idDocente');
                this.materiaDocente.Dicta = window.$(evt.target)[0].getAttribute('Dicta');
                console.log(this.materiaDocente);
                this.showMateriaDocente()
            });
        }
    },
    components: {
        dataTable,
        Loader,
        CuadernoSeguimiento
    },
    mounted() {

        this.auth = JSON.parse(localStorage.getItem('usuario'));
        if (!this.auth) {
            this.$router.push('/Login');
        } else {
            this.getPlanTrabajo();
            this.getEspecialidades();      
            this.getUnidadAcademica();
        }
    } 
};