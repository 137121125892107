import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import UsuarioPage from '@/pages/Usuario/UsuarioPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import TipoReportePage from '@/pages/TipoReporte/TipoReportePage.vue'
import TipoReporteVerPage from '@/pages/TipoReporteVer/TipoReporteVerPage.vue'
import ActitudEstudiantePage from '@/pages/ActitudEstudiante/ActitudEstudiantePage.vue'
import AsignaturaPage from '@/pages/Asignatura/AsignaturaPage.vue'
import AsignaturaBibliografiaPage from '@/pages/AsignaturaBibliografia/AsignaturaBibliografiaPage.vue'
import EspecialidadPage from '@/pages/Especialidad/EspecialidadPage.vue'
import EspecialidadAsignaturaPage from '@/pages/EspecialidadAsignatura/EspecialidadAsignaturaPage.vue'
import MateriaDocentePage from '@/pages/MateriaDocente/MateriaDocentePage.vue'
import MallaCurricularPage from '@/pages/MallaCurricular/MallaCurricularPage.vue'
import AlumnoPage from '@/pages/Alumno/AlumnoPage.vue'
import AsignacionMateriaEstudiantePage from '@/pages/AsignacionMateriaEstudiante/AsignacionMateriaEstudiantePage.vue'
import PlanTrabajoPage from '@/pages/PlanTrabajo/PlanTrabajoPage.vue'
import CuadernoSeguimientoPage from '@/pages/CuadernoSeguimiento/CuadernoSeguimientoPage.vue'
import CompetenciasPage from '@/pages/Competencias/CompetenciasPage.vue'
import PracticaPage from '@/pages/Practica/PracticaPage.vue'
import ManualPage from '@/pages/Manual/ManualPage.vue'
import AsistenciaPage from '@/pages/Asistencia/AsistenciaPage.vue'
import JefeCarreraPage from '@/pages/JefeCarrera/JefeCarreraPage.vue'
import CuadernoPage from '@/pages/JefeCarreraCuaderno/CuadernoPage.vue'



/* import MallaCurricularPage from '@/pages/MallaCurricular/MallaCurricularPage.vue'
import AlumnoPage from '@/pages/Alumno/AlumnoPage.vue' */
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Usuario',
      name: 'Usuario',
      component: UsuarioPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/TipoReporte',
      name: 'TipoReporte',
      component: TipoReportePage
    },
    {
      path: '/TipoReporteVer',
      name: 'TipoReporteVer',
      component: TipoReporteVerPage
    },
    {
      path: '/ActitudEstudiante',
      name: 'ActitudEstudiante',
      component: ActitudEstudiantePage
    },
    {
      path: '/Asignatura',
      name: 'Asignatura',
      component: AsignaturaPage
    },
    {
      path: '/AsignaturaBibliografia',
      name: 'AsignaturaBibliografia',
      component: AsignaturaBibliografiaPage
    },
    {
      path: '/Especialidad',
      name: 'Especialidad',
      component: EspecialidadPage
    },
    {
      path: '/EspecialidadAsignatura',
      name: 'EspecialidadAsignatura',
      component: EspecialidadAsignaturaPage
    }, 
    {
      path: '/MateriaDocente',
      name: 'MateriaDocente',
      component: MateriaDocentePage
    },
    {
      path: '/MallaCurricular',
      name: 'MallaCurricular',
      component: MallaCurricularPage
    },
    {
      path: '/Alumno',
      name: 'Alumno',
      component: AlumnoPage
    },
    {
      path: '/AsignacionMateriaEstudiante',
      name: 'AsignacionMateriaEstudiante',
      component: AsignacionMateriaEstudiantePage
    },
    {
      path: '/PlanTrabajo',
      name: 'PlanTrabajo',
      component: PlanTrabajoPage
    },
    {
      path: '/CuadernoSeguimiento',
      name: 'CuadernoSeguimiento',
      component: CuadernoSeguimientoPage
    },
    {
      path: '/Competencias',
      name: 'Competencias',
      component: CompetenciasPage
    },
    {
      path: '/Practica',
      name: 'Practica',
      component: PracticaPage
    },
    {
      path: '/Manual',
      name: 'Manual',
      component: ManualPage
    }
    ,
    {
      path: '/Asistencia',
      name: 'Asistencia',
      component: AsistenciaPage
    },
    {
      path: '/JefeCarrera',
      name: 'JefeCarrera',
      component: JefeCarreraPage
    },
    {
      path: '/JefeCarreraCuaderno',
      name: 'JefeCarreraCuaderno',
      component: CuadernoPage
    }
  ]
})