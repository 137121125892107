import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import DiseñoCurricular from "@/components/layout/DiseñoCurricular/DiseñoCurricular.vue";
window.$ = window.jQuery = require("jquery");

export default {
    name: "MallaCuricularPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MallaCurricularPage",
            ss: ss,
            ajax: {
                "url": ss.indexMallaCurricular(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idDocente = 0;
                    d.Materia = 0;
                    d.UnidadAcademica = 0;
                    d.Sigla = 0;
                    }
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Materia', name: 'Materia', title: 'Materia' },
                { data: 'Sigla', name: 'Sigla', title: 'Sigla' },
                { data: 'NivelAcad', name: 'NivelAcad', title: 'Nivel Acad.' },
                { data: 'Especialidad', name: 'Especialidad', title: 'Especialidad' },
                { data: 'Gestion', name: 'Gestion', title: 'Gestion' },
                { data: 'tipo', name: 'tipo', title: 'Tipo' },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad Academica' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            mallaCurricular:{},
            showMalla:{},
            seleccion:'IG',
            malla:{},
            auth:{},
            unidadAcademicas:{},
            materiaSaga:{},
            diseñoCurricular : false,
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout: 'DiseñoCurricular'
        };
    },
    methods: {
         loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.usuario.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        showDiseñoCurricularDetalle(){
            this.$refs['view-DiseñoCurricularDetalle'].show();
        },

        showDiseñoCurricular(){
            this.diseñoCurricular = true;
            this.$refs['view-DiseñoCurricularDetalle'].hide();
        },

        seleccionarMenu(valor){
            this.seleccion = valor
        },

        showContenidoAnalitico(){
            this.$refs['view-ContenidoAnalitico'].show();
        },

        AgregarCriterioDesempeio(){
            this.$refs['view-AgragarCriterioDesempenio'].show();
        },

        AgregarBibliografia(){
            this.$refs['view-AgregarBibliografia'].show();
        },

        async getMallaCurricular(){
            this.isLoading=true;

            let id = 0;

            if (this.auth.idDocente) {
                let iddoc = this.auth.idDocente;
                this.materiaSaga.idDocente = this.auth.idDocente;
                this.ajax.data = function(d){
                    d.idDocente = iddoc;
                    d.Materia = 0;
                    d.UnidadAcademica = 0;
                    d.Sigla = 0;
                }
            }


            if(this.auth.idDocente){
                id = this.auth.idDocente;
            }

            await new Promise(resolve => setTimeout(resolve, 6000));

            this.ss.getMallaCurricular(this.auth).then(
                (result) => {
                    let response = result.data;
                    this.mallaCurricular = response.data;
                    console.log(this.mallaCurricular);
                    this.$refs['datatable-MallaCurricular'].reloadAjax(this.ajax);
                    this.isLoading=false;

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                });
        },


        getMallaCurricularPost(item){
            this.isLoading=true;
            let id = 0;

            if(this.auth.idDocente){
                id = this.auth.idDocente;
            }
            this.ss.getMallaCurricularPost(item).then(
                (result) => {
                    let response = result.data;
                    this.mallaCurricular = response.data;
                    console.log(this.mallaCurricular);
                    this.isLoading=false;

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                });
        },


        cancelDiseñoCurricular(){
            this.$refs['view-DiseñoCurricularDetalle'].hide();
        },

        buscarMateriaSaga(){

            console.log(this.materiaSaga);
            
            let Materia = this.materiaSaga.materia;
            let UnidadAcademica = this.materiaSaga.unidadAcademica;
            let Sigla = this.materiaSaga.sigla;

            this.ajax.data = function(d){
                d.idDocente = 0;
                d.Materia = Materia;
                d.UnidadAcademica = UnidadAcademica;
                d.Sigla = Sigla;
            }
            this.$refs['datatable-MallaCurricular'].reloadAjax(this.ajax);

            this.getMallaCurricularPost(this.materiaSaga);

        },

        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        draw() {
            window.$('.btn-datatable-MallaCurricular').on('click', (evt) => {
                this.malla = {};
                for (let index = 0; index < this.mallaCurricular.length; index++) {
                    if (this.mallaCurricular[index].id == window.$(evt.target)[0].id){
                        this.malla = this.mallaCurricular[index];
                        index=this.mallaCurricular.length+1;
                    }
                }
                console.log('SHOW MATERIA');
                console.log(this.malla);
                this.showDiseñoCurricularDetalle();
            });
        }
    },
    components: {
        dataTable,
        Loader,
        DiseñoCurricular,
    },
    mounted() {
        this.auth = JSON.parse(localStorage.getItem('usuario'));
        if (!this.auth) {
            this.$router.push('/Login');
        } else {  
            this.getMallaCurricular();
        }
    } 
};