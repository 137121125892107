import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "AsignaturaBibliografiaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "AsignaturaBibliografiaPage",
            ss: ss,
            ajax: {
                "url": ss.indexAsignaturaBibliografia(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Especialidad', name: 'ab.Especialidad', title: 'Especialidad Asignatura' },
                { data: 'Autor', name: 'ab.Autor', title: 'Autor' },
                { data: 'Anio', name: 'ab.Anio', title: 'Anio' },
                { data: 'Titulo', name: 'ab.Titulo', title: 'Titulo' },
                { data: 'Editorial', name: 'ab.Editorial', title: 'Editorial' },
                { data: 'Edicion', name: 'ab.Edicion', title: 'Edicion' },
                { data: 'PaisCiudad', name: 'ab.PaisCiudad', title: 'Pais Ciudad' },
                { data: 'Propuesta', name: 'ab.Propuesta', title: 'Propuesta' },
                { data: 'ExisteEnBiblioteca', name: 'ab.ExisteEnBiblioteca', title: 'ExisteEnBiblioteca' },
                { data: 'URL', name: 'ab.URL', title: 'URL' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            Especialidades: [],
            asignaturabibliografias: [],
            asignaturabibliografia: {},
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        newAsignaturaBibliografia() {
            this.asignaturabibliografia = {};
            this.$refs['frm-asignaturabibliografia'].show();
        },
        showAsignaturaBibliografia(id) {
            this.isLoading=true;
            this.ss.showAsignaturaBibliografia(id).then(
                (result) => {
                    let response = result.data;
                    this.asignaturabibliografia = response.data;
                    this.$refs['view-asignaturabibliografia'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editAsignaturaBibliografia() {
            this.$refs['frm-asignaturabibliografia'].show();
            this.$refs['view-asignaturabibliografia'].hide();
        },
        getEspecialidadAsignatura() {
            this.ss.listEspecialidadAsignatura().then(
                (result) => {
                    let response = result.data;
                    this.Especialidades = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        cancelAsignaturaBibliografia() {
            if (this.asignaturabibliografia.id) {
                this.$refs['view-asignaturabibliografia'].show();
            }
            this.$refs['frm-asignaturabibliografia'].hide();
        },
        saveAsignaturaBibliografia() {
            this.ss.storeAsignaturaBibliografia(this.asignaturabibliografia).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-asignaturabibliografia'].hide();
                    this.$refs['datatable-asignaturabibliografia'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteAsignaturaBibliografia() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyAsignaturaBibliografia(this.asignaturabibliografia)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-asignaturabibliografia'].hide();
                                this.$refs['datatable-asignaturabibliografia'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-AsignaturaBibliografia').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showAsignaturaBibliografia(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
            this.$router.push('/Login');
        }else {
            this.getEspecialidadAsignatura();

        }
    }
};
