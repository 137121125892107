/* eslint-disable */ // eslint-disable-next-line
import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "MateriaDocentePage",
    data() {
        let ss = new MainService();
        return {
            msg: "MateriaDocentePage",
            ss: ss,
 
            ajax: {
                "url": ss.indexMateriaDocente(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Materia', name: 'Materia', title: 'Materia' },
                { data: 'Sigla', name: 'Sigla', title: 'Sigla' },
                { data: 'Gestion', name: 'Gestion', title: 'Gestion' },
                { data: 'Dicta', name: 'Dicta', title: 'Dicta' },
                { data: 'Docente', name: 'Docente', title: 'Docente' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
                {
                    data: 'action2',
                    orderable: false,
                    title: 'Asistencia',
                    searchable: false
                },
            ],

  /*           ajaxAsistencia: {
                "url": ss.indexAsistencia(),
                headers: ss.getToken(),
            },
            columnsAsistencia: [
                { data: 'id', name: 'id', orderable: false, searchable: false , visible: false},
                    { data: 'Fecha', name: 'a.Fecha', title: 'Fecha'},
                    { data: 'Efectivo', name: 'Efectivo', title: 'Efectivo', searchable: false, orderable: false, className: 'text-center' },
                    { data: 'Asiste', name: 'a.Asiste', title: 'Asiste', searchable: false, orderable: false, className: 'text-center' },
                    { data: 'Permiso', name: 'Permiso', title: 'Permiso', searchable: false, orderable: false, className: 'text-center' },
                    { data: 'Falta', name: 'Falta', title: 'Falta', searchable: false, orderable: false, className: 'text-center' },
                    { data: 'BajaMedica', name: 'BajaMedica', title: 'BajaMedica', searchable: false, orderable: false, className: 'text-center' },
                    { data: 'Guardia', name: 'Guardia', title: 'Guardia', searchable: false, orderable: false, className: 'text-center' },
                    { data: 'Forman', name: 'Forman', title: 'Forman', searchable: false, orderable: false, className: 'text-success  text-center font-weight-bold' },
                    { data: 'NoForman', name: 'NoForman', title: 'NoForman', searchable: false, orderable: false, className: 'text-danger text-center font-weight-bold'},
                    { data: 'Total', name: 'Total', title: 'Total', searchable: false, orderable: false , className: 'text-info text-center font-weight-bold'},
                    { data: 'action', name: 'action', orderable: false, searchable: false },
            ], */


            ajaxAsistenciaDetalle: {
                "url": ss.indexAsistenciaDetalle(),
                headers: ss.getToken(),
            },
            /* columnsAsistenciaDetalle: [
                { data: 'id', name: 'id', orderable: false, searchable: false , visible: false},
                    { data: 'idAlumno', name: 'idAlumno', title: 'Alumno'},
                    { data: 'ValorAsistencia', name: 'ValorAsistencia', title: 'ValorAsistencia', searchable: false, orderable: false, className: 'text-center' },
                    { data: 'Observacion', name: 'Observacion', title: 'Observacion', searchable: false, orderable: false, className: 'text-center',width: '300px' },
            ], */

            asistenciadetalle: {},
            asistenciaReporte: {},
            listaAsistenciaReporte: {},
            parteAsistenciaReporte: {},
            parteDelDia: {},
            asignarEstudiante: {},
            selectedHitAl: 0,
            selectedHitAl2: 0,
            fechaActual: '',
            listaAlumnos: {},
            listaAlumno2: {},
            listaAlumnosAsistencia: {},
            tipoClase: {},
            cadenaBusqueda: '',
            materiadocentes: [],
            materiadocentes2: {},
            showMateriaDocente: {},
            showMateriaAsistencia: {},
            Especialidades: [],
            materiadocente: {},
            asistencia: {},
            isLoading: false,
            errorBag: {},
            estudiante: [],
            buscar: '',
            hAsistencia: {},
           
            estudiante: {},
            buscar: '',
            estudianteSelect : '',
            indexAlumno:0,
            indexRepoerteAsistencia:0,
            alumnoBuscadoEfectivo:{},
        };
    },
    methods: {

        showAlumnoEfectivo(value){
            this.alumnoBuscadoEfectivo.Alumno = value;
            console.log(this.alumnoBuscadoEfectivo.Alumno);
        },

        newMateriaDocente() {
            this.materiadocente = {};
            this.$refs['frm-materiadocente'].show();
        },

        seleccionarEstudiante($item) {
            console.log(this.listaAlumnosAsistencia[$item].Alumno);
            console.log(this.listaAlumnosAsistencia.length);
            this.estudianteSelect = this.listaAlumnosAsistencia[$item].Alumno;
            this.indexAlumno = $item;
        },

        asistenciaEstudiante($item){

            switch ($item) {
                case 'Asiste':
                    this.listaAlumnosAsistencia[this.indexAlumno].NombreAsistencia = $item;
                    this.listaAlumnosAsistencia[this.indexAlumno].TipoAsistencia_id = 1;
                    break;

                case 'Falta':
                    this.listaAlumnosAsistencia[this.indexAlumno].NombreAsistencia = $item;
                    this.listaAlumnosAsistencia[this.indexAlumno].TipoAsistencia_id = 2;
                    break;

                case 'Permiso':
                    this.listaAlumnosAsistencia[this.indexAlumno].NombreAsistencia = $item;
                    this.listaAlumnosAsistencia[this.indexAlumno].TipoAsistencia_id = 3;
                    break;

                case 'Guardia':
                    this.listaAlumnosAsistencia[this.indexAlumno].NombreAsistencia = $item;
                    this.listaAlumnosAsistencia[this.indexAlumno].TipoAsistencia_id = 4;
                    break;
            }

            if( this.indexAlumno < this.listaAlumnosAsistencia.length){
                this.seleccionarEstudiante(this.indexAlumno + 1);
            }
            else{
                this.seleccionarEstudiante(this.listaAlumnosAsistencia.length);
            }
        },

        editMateriaDocente() {
            this.$refs['frm-materiadocente'].show();
            this.$refs['view-asistencia'].hide();
            this.isLoading=false;
        },
        cancelMateriaDocente() {
            if (this.materiadocente.id) {
                this.$refs['view-asistencia'].show();
            }
            this.$refs['frm-materiadocente'].hide();
            this.isLoading=false;
        },
        saveMateriaDocente() {
            this.ss.storeMateriaDocente(this.materiadocente).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-materiadocente'].hide();
                    this.$refs['datatable-materiadocente'].reload();
                    this.isLoading=false;
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        saveAlumnos() {

            console.log(this.asignarEstudiante);
            
              this.ss.saveAlumnos(this.asignarEstudiante).then(
                (result) => {
                    console.log(result);
                    let response = result;
                    this.$bvToast.toast(
                        'Estudiante Agregado Satisfactoriamente',
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 3000
                        }
                    )
                    this.$refs['view-alumno'].hide();

                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                this.ss.showAsistencia123(this.showMateriaDocente).then(
                    (result) => {
                        let response = result.data;
                        this.listaAlumnos = response.data;
                        this.asistenciadetalle = response.data;
                        this.asignarEstudiante.idCurso = this.listaAlumnos[0].idCurso;
                        this.asignarEstudiante.idMateria = this.listaAlumnos[0].idMateria;
                        this.asignarEstudiante.idDocente = this.listaAlumnos[0].idDocente;
                        this.asignarEstudiante.Dicta = this.listaAlumnos[0].Dicta;
                        this.$refs['view-asistencia'].reload();
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                });  
        },

        newAsistencia() {
            this.asistencia = {};
            this.$refs['frm-asistenciastore'].show();
            this.isLoading=false;
        },
       

        showAsistencia123(id) {
            this.isLoading=true;
            this.listaAlumnos = {};
            
            this.ss.showAsistencia123(id).then(
                (result) => {
                    let response = result.data;
                    this.listaAlumnos = response.data;
                    this.asistenciadetalle = response.data;   
                    this.$refs['view-asistencia'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });

        },

        
        
    listarAlumnos() {
    
            this.listaAlumno2 = {};
            this.ss.listarAlumnos().then(
                (result) => {
                    let response = result.data;
                    this.listaAlumno2 = response.data;
                    this.$refs['view-alumno'].show();
                    //console.log(this.listaAlumno2);
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },


        showMateriasEstudiante(id) {
            this.listaAlumnos = {};
            this.isLoading=true;
            this.ss.showMateriasEstudiante(id).then(
                (result) => {
                    let response = result.data;
                    this.listaAlumnos = response.data;
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        saveAsistencia() {
            //ss.asistencia.MateriaDocente = ss.materiadocente.id;
            this.asistencia.MateriaDocente = this.materiadocente.id;
            this.ss.storeAsistencia(this.asistencia).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-asistenciastore'].hide();
                    this.$refs['datatable-asistencia'].reload();
                    this.isLoading=false;
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        HistorialAsistencia() {
            //this.isLoading=true;
            this.hAsistencia = {};
            this.ss.HistorialAsistencia().then(
                (result) => {
                    let response = result.data;
                    this.hAsistencia = response.data;
                    this.asistenciadetalle = response.data;
                    this.ReporteAsistencia(this.asistenciadetalle[0].id)
                    this.$refs['view-alumnosAsistencia'].show();
                    console.log(this.asistenciadetalle);
                    console.log(this.asignarEstudiante);
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        ReporteAsistencia(id) {
            //this.isLoading=true;
            this.parteDelDia.Fecha='';
            this.parteDelDia.Efectivo=0;
            this.parteDelDia.Asistencia=0;
            this.parteDelDia.Falta=0;
            this.parteDelDia.Permiso=0;
            this.parteDelDia.Guardia=0;
            this.parteDelDia.Forman=0;
            this.parteDelDia.NoForman=0;
            this.parteDelDia.Total=0;

            console.log(id);
            this.ss.showReporteAsistecia(id).then(
                (result) => {
                    let response = result.data.data;
                    this.listaAsistenciaReporte=response;
                    console.log('LISTA ASISTENCIA REPORTE');
                    console.log(this.listaAsistenciaReporte);
                    console.log('ID LISTAR ALUMNO');
                    this.indexRepoerteAsistencia = this.listaAsistenciaReporte[0].AlumnoAsistencia_id;
                    console.log(this.indexRepoerteAsistencia);
                    this.parteDelDia.Fecha = this.listaAsistenciaReporte[0].Fecha;

                    for (let index = 0; index < this.listaAsistenciaReporte.length; index++) {

                        this.parteDelDia.Efectivo += this.listaAsistenciaReporte[index].Cantidad;
                        this.parteDelDia.Total += this.listaAsistenciaReporte[index].Cantidad;

                        switch (this.listaAsistenciaReporte[index].NombreAsistencia) {
                            case 'Asiste':
                                this.parteDelDia.Asistencia = this.listaAsistenciaReporte[index].Cantidad;
                                this.parteDelDia.Forman = this.listaAsistenciaReporte[index].Cantidad;
                                break;
                            case 'Falta':
                                this.parteDelDia.Falta = this.listaAsistenciaReporte[index].Cantidad;
                                this.parteDelDia.NoForman += this.listaAsistenciaReporte[index].Cantidad;
                                break;
                            case 'Permiso':
                                this.parteDelDia.Permiso = this.listaAsistenciaReporte[index].Cantidad;
                                this.parteDelDia.NoForman += this.listaAsistenciaReporte[index].Cantidad;
                                break;
                            case 'Guardia':
                                this.parteDelDia.Guardia = this.listaAsistenciaReporte[index].Cantidad;
                                this.parteDelDia.NoForman += this.listaAsistenciaReporte[index].Cantidad;
                                break;
                        }
                        
                    }

                    console.log('PARTE DEL DIA');
                    console.log(this.parteDelDia);


                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        ListarAlumnoAsistencia(){
            this.$refs['view-alumnosAsistenciaDetalle'].show();
            this.VerAlumnoAsistencia();
        },


        consultaAsistenciaDiaria(){

            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-success',
                  cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
              })
              
              let fecha = new Date();

                let año = fecha.getFullYear();
                let mes = fecha.getMonth() + 1; 
                let dia = fecha.getDate();

                if(mes <10 ){
                    mes = '0'+mes;
                }
                if(dia <10 ){
                    dia = '0'+dia;
                }

                const fechaActualTexto = `${año}-${mes}-${dia}`;


                this.ss.HistorialAsistencia(this.showMateriaAsistencia).then(
                    (result) => {
                        let response = result.data;
                        this.asistenciadetalle = response.data;
                        if (this.asistenciadetalle[0].Fecha == fechaActualTexto || this.asistenciadetalle[0] == null) {

                            swalWithBootstrapButtons.fire({
                                title: 'Asistencia Diaria',
                                text: "¿ Acceder al historial de asistencia ?",
                                icon: 'warning',
                                showCancelButton: true,
                                cancelButtonText: 'Cancelar',
                                confirmButtonText: 'Aceptar',
                                confirmButtonColor: '#5cb85c',
                                cancelButtonColor: '#d33',
                                reverseButtons: true
                              }).then((result) => {
                                if (result.isConfirmed) {
                                    this.AsistenciaMenu();
                                } else if (
                                  result.dismiss === this.$swal.DismissReason.cancel
                                ) {

                                }
                              })
                        }
                        else{
                            console.log('No Creado');
                            swalWithBootstrapButtons.fire({
                                title: 'Asistencia Diaria',
                                text: "¿ Crear Asistencia Diaria ?",
                                icon: 'warning',
                                showCancelButton: true,
                                cancelButtonText: 'No, No crear !',
                                confirmButtonText: 'Si, Crear !',
                                confirmButtonColor: '#5cb85c',
                                cancelButtonColor: '#d33',
                                reverseButtons: true
                              }).then((result) => {
                                if (result.isConfirmed) {
                
                                    this.showMateriaAsistencia.Fecha = fechaActualTexto;
                                    this.createAlumnosAsistencia(this.showMateriaAsistencia);

                                    this.ss.HistorialAsistencia(this.showMateriaAsistencia).then(
                                        (result) => {
                                            let response = result.data;
                                            this.asistenciadetalle = response.data;
                                            this.AsistenciaMenu();
                                            swalWithBootstrapButtons.fire(
                                                'Asistencia Creada !',
                                                'Accediendo al historia de asistencias.',
                                                'success'
                                              )
                                        }
                                    ).catch(error => {
                                        console.log(error);
                                        this.isLoading=false;
                                    }); 

                                } else if (
                                  result.dismiss === this.$swal.DismissReason.cancel
                                ) {
                                  swalWithBootstrapButtons.fire(
                                    'Asistencia no creada !',
                                    'Accediendo al historia de asistencias.',
                                    'error'
                                  )
                                  this.AsistenciaMenu();
                                }
                              })
                        } 
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                });

        },


        VerAlumnoAsistencia() {
            console.log(this.indexRepoerteAsistencia);
            this.ss.ListarAlumnoAsistencia(this.indexRepoerteAsistencia).then(
                (result) => {
                    let response = result.data.data;
                    this.listaAlumnosAsistencia=response;
                    console.log('LISTAR ALUMNOS ASISTENCIA');
                    console.log(this.listaAlumnosAsistencia);
                    this.estudianteSelect = this.listaAlumnosAsistencia[0].Alumno;
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            }); 
        },

        TomarAlumnoAsistencia() {

            console.log(this.indexRepoerteAsistencia);
            this.ss.ListarAlumnoAsistencia(this.indexRepoerteAsistencia).then(
                (result) => {
                    let response = result.data.data;
                    this.listaAlumnosAsistencia=response;
                    console.log('LISTAR ALUMNOS ASISTENCIA');
                    console.log(this.listaAlumnosAsistencia);
                    this.estudianteSelect = this.listaAlumnosAsistencia[0].Alumno;
                    this.getTipoClase()

                    this.$refs['view-asistencia2'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            }); 
        },
       
        getTipoClase(){
            console.log('GET TIPO CLASE');
            this.ss.indexTipoClase().then(
                (result) => {
                    let response = result.data;
                    this.tipoClase = response.data;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        HistorialAsistenciaDetalle(id) {
            //this.isLoading=true;
            this.hAsistencia = {};
            this.ss.HistorialAsistencia(id).then(
                (result) => {
                    let response = result.data;
                    this.hAsistencia = response.data;
                    this.asistenciadetalle = response.data;
                    this.asignarEstudiante.idCurso = this.listaAlumnos[0].idCurso;
                    this.asignarEstudiante.idMateria = this.listaAlumnos[0].idMateria;
                    this.asignarEstudiante.idDocente = this.listaAlumnos[0].idDocente;
                    this.asignarEstudiante.Dicta = this.listaAlumnos[0].Dicta;
                    this.$refs['view-hasistenciaDetalle'].show();
                    console.log(this.hAsistencia);
                    console.log(this.asignarEstudiante);
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        saveAsistenciaDetalle() {

            console.log(this.listaAlumnosAsistencia);
            this.listaAlumnosAsistencia[0].tamaño = this.listaAlumnosAsistencia.length;

             this.ss.storeAlumnosAsistencia(this.listaAlumnosAsistencia).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.$refs['view-asistencia2'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });  
        },

        cancelAsistenciaDetalle() {
            this.$refs['view-asistenciadetalle'].hide();
            this.$refs['view-asistencia'].show();
            this.isLoading=false;
        },
        cancelAsistencia() {
            this.$refs['view-asistencia2'].hide();
            this.isLoading=false;
        },

        AsistenciaMenu() {
            this.HistorialAsistencia();
            this.$refs['view-alumnosAsistencia'].show();
            this.isLoading=false;
        },

        getMateriaDocentes(){
            console.log('MATERIAS-DOCENTES');
            this.isLoading=true;
            this.ss.getCuadernoSeguimiento().then(
                (result) => {
                    let response = result.data;
                    this.materiadocentes2 = response.data;
                    this.isLoading=false;
                    console.log(this.materiadocentes2);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                });
        },

        createAlumnosAsistencia(item){
            console.log('createAlumnosAsistencia');
            this.isLoading=true;
            this.ss.createAlumnosAsistencia(item).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.AsistenciaMenu();
                    this.isLoading=false;
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                });
        },

        draw() {
            window.$('.btn-datatable-MateriaDocente').on('click', (evt) => {
                this.asignarEstudiante.id = parseInt(window.$(evt.target)[0].id);

                this.showMateriaDocente = {};
                for (let index = 0; index < this.materiadocentes2.length; index++) {
                    if (this.materiadocentes2[index].id == window.$(evt.target)[0].id){
                        this.showMateriaDocente = this.materiadocentes2[index];
                        index=this.materiadocentes2.length+1;
                    }
                }

                this.asignarEstudiante.idCurso = this.showMateriaDocente.idCurso;
                this.asignarEstudiante.idMateria = this.showMateriaDocente.idMateria;
                this.asignarEstudiante.idDocente = this.showMateriaDocente.idDocente;
                this.asignarEstudiante.Dicta = this.showMateriaDocente.Dicta; 

                console.log(this.asignarEstudiante);

                this.showAsistencia123(this.showMateriaDocente);
            });

            window.$('.btn-datatable-MateriaDocenteAsistencia').on('click', (evt) => {


                this.asignarEstudiante.id = parseInt(window.$(evt.target)[0].id);
                this.showMateriaAsistencia = {};
                for (let index = 0; index < this.materiadocentes2.length; index++) {
                    if (this.materiadocentes2[index].id == window.$(evt.target)[0].id){
                        this.showMateriaAsistencia = this.materiadocentes2[index];
                        index=this.materiadocentes2.length+1;
                    }
                }


                this.consultaAsistenciaDiaria();


            });

        }
    },
    watch: {
        selectedHitAl2(value) {
            //
            this.asignarEstudiante.idAlumno = value.idAlumno;

        },
       
       },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
            this.$router.push('/Login');
        } else {
            
            this.getMateriaDocentes();
        }
    }
};
