import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "MateriaCompetenciasPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MateriaCompetenciasPage",
            ss: ss,
            /* ajax: {
                "url": ss.getCompetencias(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'TituloCompetencia', name: 'TituloCompetencia', title: 'Titulo Competencia' },
                { data: 'CodigoCompetencia', name: 'CodigoCompetencia', title: 'Codigo Competencia' },
                { data: 'DescripcionCompetencia', name: 'DescripcionCompetencia', title: 'DescripcionCompetencia' },
                { data: 'Materia', name: 'Materia', title: 'Materia' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ], */
            competencias: {},
            competenciaDetalle:{},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
        };
    },
    methods: {
         loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.usuario.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },


        showCompetenciasDetalle(id){
            console.log(id);
            this.competenciaDetalle.id = id;
            console.log(this.competenciaDetalle.id);
            this.$refs['view-CompetenciaDetalle'].show();
        },

        getCompetencias(){
            this.competencias = {};
            
            this.ss.getCompetencias().then(
                (result) => {
                    let response = result;
                    this.competencias = response.data;
                    console.log(this.competencias);
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        /* draw() {
            window.$('.btn-datatable-MateriaCompetencias').on('click', (evt) => {
                this.malla = window.$(evt.target)[0].id;
            });
        } */
    },
    components: {
        dataTable,
        Loader,
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
            this.$router.push('/Login');
        } else {
            this.getCompetencias();
            //this.getMaterias();
            //this.getEspecialidades();
            //this.getUnidadesAcademicas();
        }
    } 
};