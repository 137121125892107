import MainService from "@/services/MainService.js";

window.$ = window.jQuery = require("jquery");

export default {
    name: "MallaCuricularPage",
    data() {
        let ss = new MainService();
        return {
            accion:'Crear',
            indexCriterioCompetencia: 'a',
            msg: "MallaCurricularPage",
            ss: ss,
            competencias:{},
            reporte:{},
            auth:{},
            storeCriterios1:{},
            planTrabajo : false,
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout : 'InfGeneral'
        };
    },
    methods: {
       
        },
        
    components: {
    },
    props: {
        materia: { default: "" },
    },
    mounted() {

        var usuario = JSON.parse(localStorage.getItem('usuario'));
        this.auth = usuario;
        if (!usuario) {
            this.$router.push('/Login');
        } else {
            /* this.getMaterias();
            this.getEspecialidades();
            this.getUnidadesAcademicas(); */
            console.log(this.auth);
        }
    } 
};