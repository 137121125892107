import MainService from "@/services/MainService.js";
window.$ = window.jQuery = require("jquery");

export default {
    name: "MallaCuricularPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MallaCurricularPage",
            ss: ss,
            planTrabajoObject:{},
            competencia:{},
            criterios:{},
            contenido:{},
            contenidosCriterioArray:[],
            contenidosCriterio:{},
            planTrabajo : false,
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout : 'InfGeneral'
        };
    },
    methods: {
        showInfoGeneral1(){
            this.$refs['view-PlanTrabajo'].show();

        },

        showPlanTrabajo(){
            this.planTrabajo = true;
            this.$refs['view-PlanTrabajo'].hide();

        },
        seleccionMenu(value){
            this.layout = value;
            console.log(this.layout);
        },
        showInfoGeneral(){
            this.isLoading=true;
            this.ss.showCompetencia(this.plan).then(
                (result) => {
                    let response = result.data;
                    if(response == null){
                        this.competencia = {};
                        this.criterios = {};
                        this.contenido = {};
                    }
                    else{
                        this.competencia = response[0];
                        console.log(this.competencia);
                        this.ss.showCriteriosCompetencia(this.competencia).then(
                            (result) => {
                                let response = result;
                                if(response.data == null){
                                    this.criterios = {};
                                    this.contenido = {};
                                }
                                else{
                                    this.criterios = response.data;
                                    console.log('CRITERIOOOOSSSS');
                                    console.log(this.criterios);
                                    this.criterioInf = this.criterios[0];
                                    this.ss.getCriteriosContenido(this.criterios[0]).then(
                                        (result) => {
                                            let response = result;
                                            if(response.data == null){
                                                this.contenido = {};
                                            }
                                            else{
                                                this.contenido = response.data;
                                                console.log('CONTENIDOOOOOO');
                                                console.log(this.contenido);
                                                this.contenidoInf = this.contenido[0];
                                                this.criterioSeleccion = this.contenidoInf.id;
                                                this.showContenidoTabla();
                                            }
                                        }
                                    ).catch(error => {
                                        console.log(error);
                                        this.isLoading=false;
                                    });
                                }
                            }
                            ).catch(error => {
                                console.log(error);
                                this.isLoading=false;
                            });

                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
            }); 
        },

        showContenidoTabla(){
            let contenidosAnaliticos='';
            for (let index = 0; index < this.contenido.length; index++) {
                this.ss.showContenidoAnalitico(this.contenido[index]).then(
                    (result) => {
                        let response = result;
                        this.contenidosCriterio = response.data;
                        console.log('contenidoAnalitico');
                        console.log(this.contenidosCriterio);
                        for (let index = 0; index < this.contenidosCriterio.length; index++) {
                            contenidosAnaliticos += this.contenidosCriterio[index].Nivel + " " + this.contenidosCriterio[index].ContenidoAnalitico + "\n";
                            if( index == this.contenidosCriterio.length-1){
                                let objeto = {
                                    'id': this.contenidosCriterio[index].CodigoCriterio,
                                    'contenido' : contenidosAnaliticos
                                }
                                console.log(contenidosAnaliticos);
                                this.contenidosCriterioArray.push(objeto);
                                console.log(this.contenidosCriterioArray);
                                contenidosAnaliticos='';
                                objeto = {
                                }
                            }
                        } 
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                }); 
            }
        },
    },
    components: {

    },
    props: {
        plan: { default: "" },
    },
    mounted() {
        this.showInfoGeneral();
    } 
};