import MainService from "@/services/MainService.js";
import InfGeneral from "@/components/layout/PlanTrabajo/InfGeneral/InfGeneral.vue";
import Esquema from "@/components/layout/PlanTrabajo/Esquema.vue";
import Competencias from "@/components/layout/PlanTrabajo/Competencias.vue";
import Criterios from "@/components/layout/PlanTrabajo/Criterios.vue";
import Resumen from "@/components/layout/PlanTrabajo/Resumen.vue";
import Bibliografia from "@/components/layout/PlanTrabajo/Bibliografia.vue";

window.$ = window.jQuery = require("jquery");

export default {
    name: "MallaCuricularPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MallaCurricularPage",
            ss: ss,
            planTrabajoObject:{},
            dicta:'',
            competencia:{},
            criterios:[],
            planTrabajo : false,
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout : 'InfGeneral'
        };
    },
    methods: {
        showInfoGeneral(){
            this.$refs['view-PlanTrabajo'].show();

        },

        showPlanTrabajo(){
            this.planTrabajo = true;
            this.$refs['view-PlanTrabajo'].hide();

        },
        seleccionMenu(value){
            this.layout = value;
            console.log(this.layout);
        },
        showInfoGeneral() {
            this.isLoading = true;
            this.ss.showCompetencia(this.plan).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(!response){
                        this.competencia = {};
                        this.criterioInf = [];
                    }
                    else{
                        this.competencia = response[0];
                        this.ss.showCriteriosCompetencia(this.competencia).then(
                            (result) => {
                                let response = result;
                                this.criterios = response.data;
                                console.log(this.criterios);
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                        });
                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                });
        },
    },
    components: {
        InfGeneral,
        Esquema,
        Competencias,
        Criterios,
        Resumen,
        Bibliografia
    },
    props: {
        plan: { default: "" },
    },
    mounted() {
        this.isLoading=true;
        this.planTrabajoObject = this.plan;
        console.log(this.planTrabajoObject);
        if(this.planTrabajoObject.Dicta == 'T' || this.planTrabajoObject.Dicta == 'Teorico/Practico' ){
            this.dicta = 'Teorico/Practico';
        }
        else{
            this.dicta = 'Laboratorio';
        }
        this.showInfoGeneral()
    } 
};