import MainService from "@/services/MainService.js";

window.$ = window.jQuery = require("jquery");

export default {
    name: "CriterioaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CriterioaPage",
            ss: ss,
            efectivoExamen: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
        };
    },
    methods: {
        
        getEfectivoParcial(){
            this.cuadernoInf.idParcial = 0;
            this.ss.getEfectivoParcial(this.cuadernoInf).then(
                (result) => {
                    let response = result;
                    this.efectivoExamen = response.data.data;

                    this.efectivoExamen.sort((a, b) => {
                        const nombreA = a.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        const nombreB = b.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        if (nombreA < nombreB) {
                            return -1;
                        }
                        if (nombreA > nombreB) {
                            return 1;
                        }
                        return 0; // nombres iguales
                    });

                    console.log(response);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        imprimirResumen(){
            this.ss.imprimirResumen(this.cuadernoInf).then(
                (response) => {
                    // Crear un blob con el tipo adecuado para PDF
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    
                    // Crear una URL para el blob y abrirla en una nueva ventana
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, '_blank');
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        saveExamenParcialObtativo(){

            console.log(this.efectivoExamen.length);

            for (let index = 0; index < this.efectivoExamen.length; index++) {

                if(this.efectivoExamen[index].ExParcial.NotaExamenObtativo > 10){
                    alert('La nota del Parcial no puede ser mayor a 10 !');
                    return;
                }
    
                if(this.efectivoExamen[index].ExParcial.NotaExamenObtativo <= 0){
                    alert('La nota del Parcial no puede ser menor a 1 !');
                    return;
                }
            }

            this.ss.storeGlobalExamenParcial(this.efectivoExamen).then(
                (result) => {
                    let response = result;
                    console.log(response);
                    this.$bvToast.toast(
                        response.data.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

    },
    props: {
        cuadernoInf: { default: '' },
    },
    mounted() {
        this.getEfectivoParcial();
    }
};