import MainService from "@/services/MainService.js";
import InfGeneral from "@/components/layout/DiseñoCurricular/InformacionGeneral.vue";
import Esquema from "@/components/layout/PlanTrabajo/Esquema.vue";
import Competencias from "@/components/layout/PlanTrabajo/Competencias.vue";
import Criterios from "@/components/layout/PlanTrabajo/Criterios.vue";
import Resumen from "@/components/layout/DiseñoCurricular/Resumen.vue";
import Bibliografia from "@/components/layout/DiseñoCurricular/Bibliografia.vue";

window.$ = window.jQuery = require("jquery");

export default {
    name: "MallaCuricularPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MallaCurricularPage",
            ss: ss,
            malla:{},
            planTrabajo : false,
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout : 'InfGeneral'
        };
    },
    methods: {
        showInfoGeneral(){
            this.$refs['view-PlanTrabajo'].show();

        },

        showPlanTrabajo(){
            this.planTrabajo = true;
            this.$refs['view-PlanTrabajo'].hide();

        },
        seleccionMenu(value){
            this.layout = value;
            console.log(this.layout);
        },
    },
    components: {
        InfGeneral,
        Esquema,
        Competencias,
        Criterios,
        Resumen,
        Bibliografia
    },
    props: {
        materia: { default: '' },
    },
};