import MainService from "@/services/MainService.js";

window.$ = window.jQuery = require("jquery");

export default {
    name: "CriterioaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CriterioaPage",
            ss: ss,
            efectivoExamen: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            estado2t:false
        };
    },
    methods: {
        
        getEfectivoParcial(){
            this.cuadernoInf.idParcial = 0;
            this.ss.getEfectivo2T(this.cuadernoInf).then(
                (result) => {
                    let response = result;
                    this.efectivoExamen = response.data.data;
                    this.estado2t = this.efectivoExamen[0].ExParcial[2].estado2t;
                    console.log(this.estado2t);
                    this.efectivoExamen.sort((a, b) => {
                        const nombreA = a.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        const nombreB = b.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        if (nombreA < nombreB) {
                            return -1;
                        }
                        if (nombreA > nombreB) {
                            return 1;
                        }
                        return 0; // nombres iguales
                    });

                    console.log(response);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        saveExamenParcial2T(){

            console.log(this.efectivoExamen.length);

            for (let index = 0; index < this.efectivoExamen.length; index++) {

                if(this.efectivoExamen[index].ExParcial[2].NotaSegundoTurno != null){
                    if(this.efectivoExamen[index].ExParcial[2].NotaSegundoTurno > 10){
                        alert('La nota del Parcial no puede ser mayor a 10 !');
                        return;
                    }
        
                    if(this.efectivoExamen[index].ExParcial[2].NotaSegundoTurno <= 0){
                        alert('La nota del Parcial no puede ser menor a 1 !');
                        return;
                    }

                    const swalPreguntar = this.$swal.mixin({
                        customClass: {
                            confirmButton: "btn btn-success",
                            cancelButton: "btn btn-danger"
                        },
                        buttonsStyling: false
                    });
        
                    swalPreguntar.fire({
                        title: "Estas Seguro ?",
                        text : "Ya no podra realizar cambios en el Examen Optativo !",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Si !",
                        cancelButtonText: "No !",
                        reverseButtons: true
                    }).then((result => {
                        if(result.isConfirmed){
                            swalPreguntar.fire({
                                title: "Guardado !",
                                text: "Registros Guardados Exitosamente !",
                                icon: "success"
                            });
        
                            this.ss.storeGlobalExamen2T(this.efectivoExamen).then(
                                (result) => {
                                    let response = result;
                                    console.log(response);
                                    this.$bvToast.toast(
                                        response.data.msg,
                                        {
                                            title: 'Correcto',
                                            variant: 'success',
                                            autoHideDelay: 2000
                                        }
                                    )
                                }
                            ).catch(error => {
                                console.log(error);
                                this.isLoading = false;
                            });
                            this.getEfectivoParcial();
        
                        }
                        else if (result.dismiss === this.$swal.DismissReason.cancel){
                            swalPreguntar.fire({
                                title: "Cancelado !",
                                text: "cancelado el guardado de registros !",
                                icon: "error"
                            });
                        }
                    }));

                    

                }else{
                    continue;
                }

            } 

            /*  */
        },

        imprimirResumen(){
            this.ss.imprimirExamenSegundoTurno(this.cuadernoInf).then(
                (response) => {
                    // Crear un blob con el tipo adecuado para PDF
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    
                    // Crear una URL para el blob y abrirla en una nueva ventana
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, '_blank');
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        }

    },
    props: {
        cuadernoInf: { default: '' },
    },
    mounted() {
        this.getEfectivoParcial();
    }
};