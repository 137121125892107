import MainService from "@/services/MainService.js";

window.$ = window.jQuery = require("jquery");

export default {
    name: "CriterioaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CriterioaPage",
            ss: ss,
            efectivoExamen: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            estado2t:false
        };
    },
    methods: {
        
        getEfectivoParcial(){
            this.cuadernoInf.idParcial = 0;
            this.ss.getEfectivo2T(this.cuadernoInf).then(
                (result) => {
                    let response = result;
                    this.efectivoExamen = response.data.data;
                    this.estado2t = this.efectivoExamen[0].ExParcial[2].estado2t;
                    console.log(this.estado2t);
                    this.efectivoExamen.sort((a, b) => {
                        const nombreA = a.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        const nombreB = b.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        if (nombreA < nombreB) {
                            return -1;
                        }
                        if (nombreA > nombreB) {
                            return 1;
                        }
                        return 0; // nombres iguales
                    });

                    console.log(response);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        imprimirResumen(){
            this.ss.imprimirExamenSegundoTurno(this.cuadernoInf).then(
                (response) => {
                    // Crear un blob con el tipo adecuado para PDF
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    
                    // Crear una URL para el blob y abrirla en una nueva ventana
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, '_blank');
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        habilitarParcial(estado){
            this.efectivoExamen[0].habilitar = estado;
            console.log(this.efectivoExamen[0]);
            const swalPreguntar = this.$swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });

            swalPreguntar.fire({
                title: "Estas Seguro ?",
                text : "Los Registros del Examen de Segundo Turno podran ser editados !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Si !",
                cancelButtonText: "No !",
                reverseButtons: true
            }).then((result => {
                if(result.isConfirmed){
                    swalPreguntar.fire({
                        title: "Habilitado !",
                        text: "Examen de Segundo Turno Habilitado Correctamente !",
                        icon: "success"
                    });

                    //console.log(this.examenParcial);
                    this.ss.habilitarExamen2T(this.efectivoExamen).then(
                        (result) => {
                            let response = result;
                            console.log(response);
                            this.$bvToast.toast(
                                response.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 2000
                                }
                            )
                            this.getEfectivoParcial();
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    });

                }
                else if (result.dismiss === this.$swal.DismissReason.cancel){
                    swalPreguntar.fire({
                        title: "Cancelado !",
                        text: "Examen de Segundo Turno sin cambios !",
                        icon: "error"
                    });
                }
            }));
        }

    },
    props: {
        cuadernoInf: { default: '' },
    },
    mounted() {
        this.getEfectivoParcial();
        console.log('EXAMEN SEGUNDO TURNO');
    }
};