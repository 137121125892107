import MainService from "@/services/MainService.js";
import InfGeneral from "@/components/layout/DiseñoCurricular/InformacionGeneral.vue";
import Esquema from "@/components/layout/PlanTrabajo/Esquema.vue";
import Competencias from "@/components/layout/PlanTrabajo/Competencias.vue";
import Criterios from "@/components/layout/PlanTrabajo/Criterios.vue";
import Resumen from "@/components/layout/PlanTrabajo/Resumen.vue";
import Bibliografia from "@/components/layout/PlanTrabajo/Bibliografia.vue";

window.$ = window.jQuery = require("jquery");

export default {
    name: "MallaCuricularPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MallaCurricularPage",
            ss: ss,
            criterios:{},
            contenido:{},
            contenidoA:{},
            contenidoshow:{},
            contenido1:{},
            showContenido:{},
            count:0,
            accion:'Crear',
            indexMateriaContenido:'a',
            selectCriterioCompetencia:{},
            planTrabajo : false,
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout : 'InfGeneral'
        };
    },
    methods: {
        showInfoGeneral(){
            this.$refs['view-PlanTrabajo'].show();

        },

        showPlanTrabajo(){
            this.planTrabajo = true;
            this.$refs['view-PlanTrabajo'].hide();

        },
        seleccionMenu(){
            this.count++;
            if(this.count ==2){
                this.contenidoshow=this.criterios[this.$refs.selectCriterioCompetencia.value];
                this.getContenidoAnalitico(this.contenidoshow);
                this.count = 0;
            }
            
        },
        getCriterios(){
                this.ss.showAllCriteriosContenido(this.competenciaCA).then(
                    (result) => {
                        let response = result;
                        if(response.data == null){
                            this.criterios = {};
                            this.contenido = {};
                        }
                        else{
                            this.criterios = response.data;
                            console.log(this.criterios);
                            this.contenidoshow = this.criterios[0];
                        
                            this.contenido1.MateriaContenido_id = this.contenidoshow.id;
                            this.contenido1.Nivel = this.contenidoshow.Nivel;
                            this.contenido1.ContenidoAnalitico = this.contenidoshow.ContenidoAnalitico;

                            this.getContenidoAnalitico(this.criterios[0]);
                        }
                        
                    }).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                    });
        },

        

        getContenidoAnalitico(item){
            this.ss.showContenidoAnalitico(item).then(
                (result) => {
                    let response = result;
                    console.log(response.data);
                    if(response.data == null){
                        this.contenido = {};
                    }
                    else{
                        this.contenido = response.data;
                        console.log(this.contenido);
                    }
                    
                }).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                });
        },

        seleccionerMateriaContenido(index){
            this.indexMateriaContenido = index;
            this.accion = 'Modificar';
            console.log(index);
            console.log(this.contenido[index]);
            this.contenido1.id = this.contenido[index].id;
            this.contenido1.NumeroContenidos = this.contenido[index].NumeroContenidos;
            this.contenido1.UnidadDidactica = this.contenido[index].UnidadDidactica;
            this.contenido1.MateriaCriterioDesempeno_id = this.contenido[index].MateriaCriterioDesempeno_id;
            this.contenido1.Nivel = this.contenido[index].Nivel;
            this.contenido1.ContenidoAnalitico = this.contenido[index].ContenidoAnalitico;
            console.log(this.contenido1);
        },
        crearMateriaContenido(){
            this.indexMateriaContenido = 'a';
            this.contenido1 = {};
            this.contenido1.MateriaContenido_id = this.contenido[0].MateriaContenido_id;
            this.accion = 'Crear';
        },

        storeMateriaContenido(){
            console.log(this.contenidoA);
            this.ss.storeContenidoAnalitico(this.contenidoA).then(
                (result) => {
                    let response = result.data;
                    console.log(response.data);
                    this.getContenidoAnalitico(this.contenidoshow);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )
                    this.$refs['frm-agregar-contenidoA'].hide();
                    this.$refs['frm-detalle-contenidoA'].hide();

                }).catch(error => {
                    console.log(error);
                    this.isLoading=false;
            });    
        },



        eliminarContenidoAnalitico(){


            this.$swal.fire({
                title: 'Eliminar Contenido Analitico ?',
                text: "Esta accion es irreversible !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Eliminar !'
              }).then((result) => {
                if (result.isConfirmed) {

                    this.$swal.fire(
                        'Eliminado!',
                        'Contenido Analitico Eliminado.',
                        'success'
                      )
                    
                      //FALTA EL SERVIVIO PARA ELIMINAR EL CONTENIDO ANALITICO

                }
              })
            
        },

        agregarContenidoA(){
            this.contenidoA={};
            this.contenidoA.MateriaContenido_id = this.contenidoshow.id
            this.$refs['frm-agregar-contenidoA'].show();
        },

        cancelarAgregarContenidoA(){
            this.$refs['frm-agregar-contenidoA'].hide();
        },

        detalleContenidoA(item){
            this.contenidoA = item;
            this.$refs['frm-detalle-contenidoA'].show();
        },

        cancelarDetalleContenidoA(){
            this.$refs['frm-detalle-contenidoA'].hide();
        }
    },
    props: {
        competenciaCA: { default: "" },
    },
    components: {
        InfGeneral,
        Esquema,
        Competencias,
        Criterios,
        Resumen,
        Bibliografia
    },
    mounted() {
        this.getCriterios();
        console.log(this.competenciaCA);
    } 
};