import MainService from "@/services/MainService.js";

window.$ = window.jQuery = require("jquery");

export default {
    name: "MallaCuricularPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MallaCurricularPage",
            ss: ss,
            criterios:{},
            detalle:{},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout : 'InfGeneral'
        };
    },
    methods: {

        getCriterios(){
        console.log(this.competencia);
            this.ss.showCriteriosCompetencia(this.competencia).then(
                (result) => {
                    let response = result;
                    if(response.data == null){
                        this.criterios = {};
                    }
                    this.criterios = response.data;
                    console.log('CRITERIOOOOSSSS');
                    console.log(this.criterios);
                }).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                });
        },

        storeCriteriosCopmetencia(){
            if(this.detalle && (this.detalle.CodigoCriterio && this.detalle.DescripcionCriterio)){
                console.log(this.detalle);
             this.ss.storeCriterioCompetencia(this.detalle).then(
                (result) => {
                    let response = result;
                    console.log(response.data);
                    this.$bvToast.toast(
                        response.data.accion,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )
                    this.$refs['frm-detalle-criterio'].hide();
                    this.$refs['frm-agregar-criterio'].hide();
                    this.getCriterios()
                }).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                }); 
            
            }
            else{
                alert('Los Campos no pueden estar vacios !');
            }

        },
            

        eliminarMateriaContenido(item){
            this.$swal.fire({
                title: 'Eliminar Criterio de Desempeño ?',
                text: "Esta accion es irreversible !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Eliminar !'
              }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal.fire(
                        'Eliminado!',
                        'Criterio de Desempeño Eliminado.',
                        'success'
                      )
                    this.ss.deleteCriterioCompetencia(item).then(
                        (result) => {
                            let response = result;
                            console.log(response.data);
                            this.getCriterios()
                        }).catch(error => {
                            console.log(error);
                            this.isLoading=false;
                        });

                }
              })
            
        },

        detalleCriterio(item){
            this.detalle = item;
            console.log(this.detalle);
            this.$refs['frm-detalle-criterio'].show();
        },

        agregarCriterio(){
            this.detalle = {};
            this.detalle.MateriaCompetencia_id = this.competencia.id;
            this.$refs['frm-agregar-criterio'].show();
        },

        cancleAgregarCriterio(){
            this.$refs['frm-agregar-criterio'].hide();
        },

        cancleDetalleCriterio(){
            this.$refs['frm-detalle-criterio'].hide();
        },

       
        
        },

    props: {
        competencia: { default: "" },
    },
    mounted() {
        this.getCriterios();
        console.log(this.competencia);
    } 
};