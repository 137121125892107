import MainService from "@/services/MainService.js";
import InfGeneral from "@/components/layout/PlanTrabajo/InfGeneral/InfGeneral.vue";
import Esquema from "@/components/layout/PlanTrabajo/Esquema.vue";
import Competencias from "@/components/layout/PlanTrabajo/Competencias.vue";
import Criterios from "@/components/layout/PlanTrabajo/Criterios.vue";
import Resumen from "@/components/layout/PlanTrabajo/Resumen.vue";
import Bibliografia from "@/components/layout/PlanTrabajo/Bibliografia.vue";
import { VBTooltip } from 'bootstrap-vue';

window.$ = window.jQuery = require("jquery");

export default {
    name: "MallaCuricularPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MallaCurricularPage",
            ss: ss,
            reactivos:{},
            reactivosAgregar:{},
            reactivosDetalle:{},
            competencia:{},
            parciales:{},
            reactivosFormativos:{},
            dicat:'',
            count:0,
            parcialCriterio:'',
            cargaHorariaCriterio:0,
            porcentajeTeo:0,
            porcentajePrac:0,
            porcentajeEvaluacionFinal:60,
            porcentajeTotal:0,
            cargaTeo:0,
            cargaPrac:0,
            cargaTotal:0,
            idTipoReactivo:0,
            criterios:{},
            criterioSeleccion:{},
            criterioCYP:{},
            contenidoSeleccion:{},
            contenido:{},
            contenidoAnalitico:{},
            planTrabajo : false,
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout : 'InfGeneral',

            Addreactivo:{},

        };
    },
    methods: {
        showAddCriterio(id){
            this.isLoading=true;
            this.index=id;

            this.arrayReactivo[this.index].TiposReactivo_id = this.reactivosDetalle.TiposReactivo_id;

            this.getCriterios();
            this.getParcial()
            this.$refs['view-AddReactovoDetalle'].show();
        },

        SaveCriterio(){
            //asignarReactivoEfectivo
            this.reactivosDetalle.reactivos = this.arrayReactivo;
            this.reactivosDetalle.tamaño = this.arrayReactivo.length;
            console.log(this.reactivosDetalle);
            this.ss.storeReactivosDetalle(this.reactivosDetalle).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )
                    
                    this.$refs['view-AddReactovoDetalle'].hide();
                    this.$refs['frm-ModificarReactovoDetalle'].hide();
                    this.calcularPorcentajes();
                    
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });  

        },

        alertPlanTrabajo(){

            this.$swal.fire({
                title: '¿ Cargar Plan de Trabajo ?',
                text: "Esta accion es irreversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Cargar!'
              }).then((result) => {
                if (result.isConfirmed) {

                    this.ss.asignarReactivoEfectivo(this.planInf).then(
                        (result) => {
                            let response = result.data;
                            console.log(response);
                            this.$swal.fire(
                                'Cargado!',
                                'Plan de Trabajo Cargado.',
                                'success'
                                )
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                    });  

                    
                } 
              })

        },

        showReactivosDetalle(cr){
            console.log(this.criterios);
            console.log(cr);
            for (let index = 0; index < this.criterios.length; index++) {
                if(this.criterios[index].id == cr.MateriaCriterioDesempeno_id){
                    this.criterioCYP = this.criterios[index];
                    break;
                }
                else{
                    continue;
                }
                
            }

            this.criterioSeleccion = cr;
            this.idTipoReactivo = cr.id;
            this.planInf.MateriaCriterioDesenmpeno_id = this.criterioCYP.id;
            this.Addreactivo.MateriaCriterioDesenmpeno_id = this.criterioCYP.id;
            this.getReactivosFormativos(this.planInf);
            this.ss.showContenidoAnalitico(this.criterioSeleccion).then(
                (result) => {
                    let response = result;
                    this.contenidoAnalitico = response.data;
                    console.log('contenidoAnalitico');
                    console.log(this.contenidoAnalitico);

                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
            this.$refs['view-ReactivosDetalle'].show();
        },

        calcularPorcentajes(reactivos){
            this.porcentajePrac=0;
            this.porcentajeTeo=0;
            this.porcentajeTotal=0;
            this.cargaTeo=0;
            this.cargaPrac=0;
            this.cargaTotal=0;
            reactivos.forEach(rec => {
                this.porcentajePrac += rec.PorPrac;
                this.porcentajeTeo += rec.PorTeo;
                this.cargaTeo += rec.HrsTeo;
                this.cargaPrac += rec.HrsPrac;
            });
            this.porcentajeTotal = this.porcentajePrac + this.porcentajeTeo;
            this.cargaTotal = this.cargaTeo + this.cargaPrac;
        },

        addReactivosDetalle(){

            if(this.parcialCriterio == ''){
                alert('Selecciones el perido formativo antes de crear los reactivos formativos !');
                return;
            }

            this.Addreactivo={};

            this.Addreactivo.IdParcial = this.parcialCriterio;
            this.Addreactivo.idMateria = this.planInf.idMateria;
            this.Addreactivo.idCurso = this.planInf.idCurso;
            this.Addreactivo.idDocente = this.planInf.idDocente;
            this.Addreactivo.Dicta = this.planInf.Dicta;
            this.Addreactivo.MateriaCriterioDesenmpeno_id = this.criterioCYP.id;

            if(this.planInf.Dicta == 'T' || this.planInf.Dicta == 'Teorico/Practico' ){
                this.Addreactivo.TiposReactivo_id = 1;
            }
            else{
                this.Addreactivo.TiposReactivo_id = 3;
            }

            console.log(this.Addreactivo);

            this.$refs['frm-AgregarReactivoDetalleTeorico'].show();
            
        },

        addReactivosDetallePractico(){
            this.AddreactivoFP={};
            this.idRFP++;
            this.AddreactivoFP.id = this.idRFP;
            this.$refs['frm-AgregarReactivoDetallePractico'].show();

        },

        detalleReactivosDetalle(id){
            
            this.ss.showReactivosFormativos(id).then(
                (result) => {
                    let response = result;
                    this.Addreactivo = response.data.data;
                    console.log(this.Addreactivo);
                    this.$refs['frm-ModificarReactovoDetalle'].show();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },


        getReactivos(){
            this.ss.indexReactivos().then(
                (result) => {
                    let response = result;
                    this.reactivos = response.data;
                    console.log('REATIVOS');
                    console.log(this.reactivos );
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        getReactivosFormativos(item){
            this.ss.listReactivosFormativos(item).then(
                (result) => {
                    let response = result;
                    this.reactivosDetalle = response.data.data;
                    console.log('REATIVOS DETALLE');
                    console.log(this.reactivosDetalle);
                    this.calcularPorcentajes(this.reactivosDetalle);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },


        /* getCompetencias(){
            this.isLoading=true;
            this.ss.showCompetencia(this.planInf).then(
                (result) => {
                    let response = result;
                    if(response.data == null){
                        console.log('Entro Aqui');
                        this.competencia.idMateria = this.parametro.idMateria;
                    }else{
                        this.competencia = response.data[0];
                        console.log('COMPETENCIAAAAAA');
                        console.log(this.competencia);

                    this.ss.showCriteriosCompetencia(this.competencia).then(
                        (result) => {
                            let response = result;
                            this.criterios = response.data;
                            console.log('CRITERIOOOOSSSS');
                            console.log(this.criterios);
                            this.ss.getCriteriosContenido(this.criterios[0]).then(
                                (result) => {
                                    let response = result;
                                    this.contenido = response.data;
                                    console.log('CONTENIDOOOOOO');
                                    console.log(this.contenido);

                                    this.ss.showContenidoAnalitico(this.contenido[0]).then(
                                        (result) => {
                                            let response = result;
                                            this.contenidoAnalitico = response.data;
                                            console.log('contenidoAnalitico');
                                            console.log(this.contenidoAnalitico);
                                            this.isLoading=false;
                                        }
                                    ).catch(error => {
                                        console.log(error);
                                        this.isLoading=false;
                                    });
                                    
                                }
                            ).catch(error => {
                                console.log(error);
                                this.isLoading=false;
                            });

                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                    });
                    }

                    
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        }, */

        getCompetencias(item){
            this.ss.showCompetencia(item).then(
                (result) => {
                    let response = result;
                    this.competencia = response.data[0];
                    console.log('COMPETENCIA');
                    console.log(this.competencia);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        getCriterios(item){
            this.ss.showCriteriosCompetencia(item).then(
                (result) => {
                    let response = result;
                    this.criterios = response.data;
                    console.log('CRITERIOOOOSSSS');
                    console.log(this.criterios);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        getContenido(item){
            this.ss.getCriteriosContenido(item).then(
                (result) => {
                    let response = result;
                    this.contenido = response.data;
                    console.log('CONTENIDOOOOOO');
                    console.log(this.contenido);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        getContenidoAnalitico(item){
            this.ss.showContenidoAnalitico(item).then(
                (result) => {
                    let response = result;
                    this.contenidoAnalitico = response.data;
                    console.log('contenidoAnalitico');
                    console.log(this.contenidoAnalitico);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        async getContenidosMateria(item){
            this.ss.showCompetencia(item).then(
                (result) => {
                    let response = result;
                    this.competencia = response.data[0];
                    console.log('COMPETENCIA');
                    console.log(this.competencia);
                    if(this.competencia){
                        this.ss.showCriteriosCompetencia(this.competencia).then(
                            (result) => {
                                let response = result;
                                this.criterios = response.data;
                                console.log('CRITERIOOOOSSSS');
                                console.log(this.criterios);
                                if(this.criterios[0]){
                                    this.ss.getCriteriosContenido(this.criterios[0]).then(
                                        (result) => {
                                            let response = result;
                                            this.contenido = response.data;
                                            console.log('CONTENIDOOOOOO');
                                            console.log(this.contenido);
                                        }
                                    ).catch(error => {
                                        console.log(error);
                                        this.isLoading=false;
                                    });
                                }else{
                                    return;
                                }
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading=false;
                        });
                    }
                    else{
                        return;
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        getParcial(){
            this.ss.listParcial().then(
                (result) => {
                    let response = result;
                    this.parciales = response.data;
                    console.log('Parciales');
                    console.log(this.parciales);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        storeReactivosDetalleAgregarTeoria(){
            console.log(this.Addreactivo);
            this.ss.storeReactivosFormativos(this.Addreactivo).then(
                (result) => {
                    let response = result;
                    console.log('Reactivos Store');
                    console.log(response.data);
                    this.$bvToast.toast(
                        response.data.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )
                    this.getReactivosFormativos(this.planInf);
                    this.$refs['frm-AgregarReactivoDetalleTeorico'].hide();
                    this.$refs['frm-ModificarReactovoDetalle'].hide();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        storeReactivosDetalleAgregarPractico(){
            
            /* this.$refs['frm-AgregarReactovoDetalle'].hide();
            this.$refs['frm-ModificarReactovoDetalle'].hide(); */
            this.ReactivosFormativosPractico.push(this.AddreactivoFP);
            console.log(this.ReactivosFormativosPractico);
            this.$refs['frm-AgregarReactivoDetallePractico'].hide();
                
        },

        storeReactivosDetalleModificar(){
            

           for(let index = 0; this.ReactivosFormativosTeorico.length; index++){
            if(this.ReactivosFormativosTeorico[index].id == this.reactivosAgregar.id){
                this.ReactivosFormativosTeorico[index] = this.reactivosAgregar;
                this.$refs['frm-ModificarReactovoDetalle'].hide();
            }
           }

           this.$refs['frm-ModificarReactovoDetalle'].hide();
            
        },

        deleteReactivosDetalle(id){

            this.ss.destroyReactivosFormativos(id).then(
                (result) => {
                    let response = result;
                    console.log(response.data);
                    this.$bvToast.toast(
                        response.data.msg,
                        {
                            title: 'Eliminado',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )
                    this.getReactivosFormativos(this.planInf);
                    this.calcularPorcentajes();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
            
          
        },

        cancelAgregarReactivo(){
           this.$refs['frm-AgregarReactivoDetalleTeorico'].hide();
        },

        cancelModificarReactivo(){
            this.$refs['frm-ModificarReactovoDetalle'].hide();
         },
 

        cargarPlanTrabajo(){

            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                  confirmButton: 'btn-success',
                  cancelButton: 'btn-danger'
                },
                buttonsStyling: true
              });
              
              swalWithBootstrapButtons.fire({
                title: 'Cargar Plan de Trabajo ?',
                text: "Sus Archivos se Cargaran y no podrán ser Modificados!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Cargar !',
                cancelButtonText: 'No, Cancelar !',
                reverseButtons: true
              }).then((result) => {
                if (result.isConfirmed) {
                    this.ss.cargarPlanTrabajo(this.planInf).then(
                        (result) => {
                            let response = result;
                            console.log(response.data);
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                    });
                  swalWithBootstrapButtons.fire(
                    'Cargado !',
                    'Sus Archivos Fueron Cargados.',
                    'success'
                  );
                } else if (result.dismiss === swal.DismissReason.cancel) {
                  swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'Sus Archivos No Fueron Cargados.',
                    'error'
                  );
                }
              });
        }

    },
    components: {
        InfGeneral,
        Esquema,
        Competencias,
        Criterios,
        Resumen,
        Bibliografia
    },
    directives: {
        'tooltip': VBTooltip
      },
    props: {
        planInf: { default: "" },
    },
    mounted() {
        /* this.getReactivos();
        this.getContenidosMateria(this.planInf);
        this.getParcial();
        this.getReactivosDetalle(this.planInf); */
        this.getContenidosMateria(this.planInf);
        this.getReactivos();
        this.getParcial();
        this.getReactivosDetalle(this.planInf);
    }
};