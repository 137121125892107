import MainService from "@/services/MainService.js";
import PrimerParcial from "@/components/layout/CuadernoSeguimiento/PrimerParcial.vue"; 
import SegundoParcial from "@/components/layout/CuadernoSeguimiento/SegundoParcial.vue"; 
import TercerParcial from "@/components/layout/CuadernoSeguimiento/TercerParcial.vue"; 
import ExamenObtativo from "@/components/layout/CuadernoSeguimiento/ExamenObtativo.vue"; 
import ExamenSegundoTurno from "@/components/layout/CuadernoSeguimiento/ExamenSegundoTurno.vue"; 
import Resumen from "@/components/layout/CuadernoSeguimiento/Resumen.vue"; 

window.$ = window.jQuery = require("jquery");

export default {
    name: "MallaCuricularPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MallaCurricularPage",
            ss: ss,
            infGeneral:{},
            planTrabajo : false,
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            parcial:{},
            layout : 'InfGeneral'
        };
    },
    methods: {
        showInfoGeneral(){
            this.$refs['view-PlanTrabajo'].show();

        },

        showPlanTrabajo(){
            this.planTrabajo = true;
            this.$refs['view-PlanTrabajo'].hide();

        },
        seleccionMenu(value){
            this.layout = value;
            this.parcial=value;
            this.infGeneral = this.cuaderno;
            console.log('CUADERNO MENU');
            console.log(this.infGeneral);
        },
    },
    components: {
        PrimerParcial,
        SegundoParcial,
        TercerParcial,
        ExamenObtativo,
        ExamenSegundoTurno,
        Resumen
    },
    props: {
        cuaderno: { default: '' },
    },

    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
            this.$router.push('/Login');
        } else {
            this.layout = 'PrimerParcial';
            this.parcial='PrimerParcial';
            this.infGeneral = this.cuaderno;
            console.log('CUADERNO MENU');
            console.log(this.infGeneral);
        }
    }
};