<template>
    <v-list nav dense>
        <v-list-item-group>

            <router-link
                class="black--text text--accent-4"
                to="Profile"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Profile")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Usuario"
                active-class="blue--text text--accent-4"
                v-if="auth.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Usuario")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
<!-- 
            <router-link
                class="black--text text--accent-4"
                to="UnidadAcademica"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-school</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.UnidadAcademica")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

           

       

   <!--          <router-link
                class="black--text text--accent-4"
                to="Especialidad"
                active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon class="fa fa-font" aria-hidden="true"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("Especialidad")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </router-link>
             -->
            <router-link
                class="black--text text--accent-4"
                to="Rol"
                active-class="blue--text text--accent-4"
                v-if="auth.Rol==1"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-key</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("nav.Rol")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </router-link>



                <v-list-group v-if="auth.Rol==1 || auth.Rol==4">
      <template v-slot:activator>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Docente") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <router-link
                class="black--text text--accent-4"
                to="PlanTrabajo"
                active-class="blue--text text--accent-4"
                v-if="auth.Rol==1 || auth.Rol==4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="fa fa-folder" aria-hidden="true"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Plan De Trabajo")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="CuadernoSeguimiento"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="fa fa-book" aria-hidden="true"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Cuaderno De Seguimiento")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

       
            <router-link
                class="black--text text--accent-4"
                to="Manual"
                active-class="blue--text text--accent-4"
                v-if="auth.Rol==1 || auth.Rol==4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="fa fa-book" aria-hidden="true"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Manual de Usuario")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

    </v-list-group>


    <v-list-group v-if="auth.Rol==1 || auth.Rol==3">
      <template v-slot:activator>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Jefe de Carrera") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <router-link
                class="black--text text--accent-4"
                to="JefeCarrera"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="fa fa-folder" aria-hidden="true"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Planes de Trabajo")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>


            <router-link
                class="black--text text--accent-4"
                to="JefeCarreraCuaderno"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="fa fa-book" aria-hidden="true"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Cuadernos de Seguimiento")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

    </v-list-group>


<!-- 
    <v-list-group>
      <template v-slot:activator>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Jefe de Carrera") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <router-link
                class="black--text text--accent-4"
                to="Asistencia"
                active-class="blue--text text--accent-4"
                v-if="auth.Rol==1 || auth.Rol==4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="fa fa-folder" aria-hidden="true"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Asistencia")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="RendimientoAcademico"
                active-class="blue--text text--accent-4"
                v-if="auth.Rol==1 || auth.Rol==4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="fa fa-book" aria-hidden="true"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Rendimiento Academico")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

       
            <router-link
                class="black--text text--accent-4"
                to="CuadernosSeguimiento"
                active-class="blue--text text--accent-4"
                v-if="auth.Rol==1 || auth.Rol==4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon class="fa fa-book" aria-hidden="true"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Cuadernos de Seguimiento")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
    </v-list-group>

 -->

            


        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    name: "MenuVuetify",
    data() {
        return {
            msg: "MenuVuetify",
            auth:{}
        };
    },
    mounted() {
        this.auth = JSON.parse(localStorage.getItem('usuario'));
        if (!this.auth) {
            this.$router.push('/Login');
        } else {
            // this.getUnidadAcademica();
            // this.getRol();
        }
    }
};
</script>