import MainService from "@/services/MainService.js";
import InfGeneral from "@/components/layout/PlanTrabajo/InfGeneral/InfGeneral.vue";
import Esquema from "@/components/layout/PlanTrabajo/Esquema.vue";
import Competencias from "@/components/layout/PlanTrabajo/Competencias.vue";
import Criterios from "@/components/layout/PlanTrabajo/Criterios.vue";
import Resumen from "@/components/layout/PlanTrabajo/Resumen.vue";
import Bibliografia from "@/components/layout/PlanTrabajo/Bibliografia.vue";
import { VBTooltip } from 'bootstrap-vue';

window.$ = window.jQuery = require("jquery");

export default {
    name: "MallaCuricularPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MallaCurricularPage",
            ss: ss,
            malla:{},
            url:'',
            auth:{},
            cargasHorarias:[],
            contenidosCriterio:[],
            bibliografias:[],
            parciales:[],
            reactivosFormativos:[],
            cargaHoraria:{},
            reactivosDetalle:{},
            cargaHorariaLaboratorio:{},
            contenidoAnalitico:[],
            Addreactivo:{},
            cargaHorariaTeoria:{},
            idParcial:0,
            competencia: {},
            criterios: {},
            criterioSelect: {},
            contenido: {},
            cargaHorariaCriterio:0,
            porcentajeTeo:0,
            porcentajePrac:0,
            porcentajeEvaluacionFinal:0,
            porcentajeTotal:0,
            cargaTeo:0,
            cargaPrac:0,
            cargaTotal:0,
            planTrabajo : false,
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            layout : 'InfGeneral',
            file: null,
            imageUrl: null,
        };
    },
    methods: {


        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.plan.Esquema = result.data.data;
                            console.log(this.plan);
                            this.saveMateriaDocente()
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        getParcial(){
            this.ss.listParcial().then(
                (result) => {
                    let response = result;
                    this.parciales = response.data;
                    console.log('Parciales');
                    console.log(this.parciales);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },


        handleFileChange() {
            this.imageUrl = URL.createObjectURL(this.file);
            console.log(this.imageUrl);
          },
          
          uploadFile() {
            // Lógica para subir el archivo al servidor
            // Aquí puedes usar Axios u otra biblioteca para hacer la solicitud HTTP
            // Por simplicidad, solo mostraremos un mensaje en la consola en este ejemplo
            console.log('Subiendo el archivo:', this.file);
          },

        cancelAddReactivo(){
            this.$refs['frm-AgregarReactivoFormativo'].hide();

        },

        showDatos(){
            this.$refs['view-datos'].show();

        },

        showCompetencias(){
            this.$refs['view-competencias'].show();

        },

        showBibliografias(){
            this.$refs['view-bibliografia'].show();

        },

        showEsquema(){
            this.$refs['view-esquema'].show();

        },

        storeReactivosFormativos(item){

            

            if(item){
                this.Addreactivo = { ...item };
            }else{

                if(this.idParcial == 0){

                    this.$swal.fire(
                        'Ooops...',
                        'Debe seleccionar el periodo evualativo previamente',
                        'error'
                      )
                    return;
                }

                if(this.porcentajeTotal < 100){
                    this.Addreactivo={};
        
                    this.Addreactivo.idMateria = this.plan.idMateria;
                    this.Addreactivo.IdParcial = this.idParcial;
                    this.Addreactivo.idCurso = this.plan.idCurso;
                    this.Addreactivo.idDocente = this.plan.idDocente;
                    this.Addreactivo.Dicta = this.plan.Dicta;
                    this.Addreactivo.MateriaCriterioDesenmpeno_id = this.criterioSelect.MateriaCriterioDesempeno_id;
        
                    if(this.plan.Dicta == 'T' || this.plan.Dicta == 'Teorico/Practico' ){
                        this.Addreactivo.TiposReactivo_id = 1;
                    }
                    else{
                        this.Addreactivo.TiposReactivo_id = 3;
                    }
                }

            }
            console.log(this.Addreactivo);
            this.$refs['frm-AgregarReactivoFormativo'].show();

        },

        saveReactivoFormativo(){

             this.ss.storeReactivosFormativos(this.Addreactivo).then(
                (result) => {
                    let response = result;
                    console.log('Reactivos Store');
                    console.log(response.data);
                    this.$bvToast.toast(
                        response.data.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )
                    this.$refs['frm-AgregarReactivoFormativo'].hide();
                    this.plan.MateriaCriterioDesenmpeno_id = this.criterioSelect.MateriaCriterioDesempeno_id;
                    this.getReactivosFormativos(this.plan);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        deleteReactivosDetalle(item){

            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                  confirmButton: 'btn-success',
                  cancelButton: 'btn-danger'
                },
                buttonsStyling: true
              });
              
              swalWithBootstrapButtons.fire({
                title: 'Eliminar Reactivo Formativo ?',
                text: "Esta Accion es irreversible !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Eliminar !',
                cancelButtonText: 'No, Cancelar !',
                reverseButtons: true
              }).then((result) => {
                if (result.isConfirmed) {
                    this.ss.destroyReactivosFormativos(item).then(
                        (result) => {
                            let response = result;
                            console.log(response.data);
                            this.$bvToast.toast(
                                response.data.msg,
                                {
                                    title: 'Eliminado',
                                    variant: 'danger',
                                    autoHideDelay: 2000
                                }
                            )
                            this.plan.MateriaCriterioDesenmpeno_id = this.criterioSelect.id;
                            this.getReactivosFormativos(this.plan);
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                    });
                  swalWithBootstrapButtons.fire(
                    'Eliminado !',
                    'Reactivo Formativo Eliminado.',
                    'success'
                  );
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                  swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'Reactivo No Eliminado.',
                    'error'
                  );
                }
              });
  
          
        },

        selectCriterio(item){
            if(item.id){
                this.criterioSelect = item;
                console.log(this.criterioSelect);
                this.plan.MateriaCriterioDesenmpeno_id = item.MateriaCriterioDesempeno_id;
                this.getReactivosFormativos(this.plan);
            }else{
                this.criterioSelect = item;
            }
        },

        showPlanTrabajo(){
            this.planTrabajo = true;
            this.$refs['view-PlanTrabajo'].hide();

        },

        showCargaHoraria(id){
            this.ss.shorCargaHoraria(id).then(
                (result) => {
                    let response = result;
                    if(response.data){
                        this.cargasHorarias = response.data.data;
                        console.log(this.cargasHorarias);
                        if(this.cargasHorarias.length > 1){
                            if(this.cargasHorarias[0].idTipoEvaluacion == 'T'){
                                this.cargaHorariaTeoria = this.cargasHorarias[0];
                                this.cargaHorariaLaboratorio = this.cargasHorarias[1];

                                this.cargaHoraria.Materia = this.cargaHorariaTeoria.Materia;
                                this.cargaHoraria.Sigla = this.cargaHorariaTeoria.Sigla;
                                this.cargaHoraria.HorasT = this.cargaHorariaTeoria.Horas;
                                this.cargaHoraria.HorasL = this.cargaHorariaLaboratorio.Horas;
                                this.cargaHoraria.Creditos = this.cargaHorariaTeoria.Creditos;
                                this.creditos = this.cargaHorariaTeoria.Creditos;
                            }else{
                                this.cargaHorariaTeoria = this.cargasHorarias[1];
                                this.cargaHorariaLaboratorio = this.cargasHorarias[0];

                                this.cargaHoraria.Materia = this.cargaHorariaTeoria.Materia;
                                this.cargaHoraria.Sigla = this.cargaHorariaTeoria.Sigla;
                                this.cargaHoraria.HorasT = this.cargaHorariaTeoria.Horas;
                                this.cargaHoraria.HorasL = this.cargaHorariaLaboratorio.Horas;
                                this.cargaHoraria.Creditos = this.cargaHorariaLaboratorio.Creditos;
                                this.creditos = this.cargaHorariaLaboratorio.Creditos;

                            }
                        }else{
                            if(this.cargasHorarias[0].idTipoEvaluacion == 'T'){
                                this.cargaHorariaTeoria = this.cargasHorarias[0];

                                this.cargaHoraria.Materia = this.cargaHorariaTeoria.Materia;
                                this.cargaHoraria.Sigla = this.cargaHorariaTeoria.Sigla;
                                this.cargaHoraria.HorasT = this.cargaHorariaTeoria.Horas;
                                this.cargaHoraria.HorasL = 0;
                                this.cargaHoraria.Creditos = this.cargaHorariaTeoria.Creditos;
                                this.creditos = this.cargaHorariaTeoria.Creditos;

                            }else{
                                this.cargaHorariaLaboratorio = this.cargasHorarias[0];

                                this.cargaHoraria.Materia = this.cargaHorariaLaboratorio.Materia;
                                this.cargaHoraria.Sigla = this.cargaHorariaLaboratorio.Sigla;
                                this.cargaHoraria.HorasL = this.cargaHorariaLaboratorio.Horas;
                                this.cargaHoraria.HorasT = 0;
                                this.cargaHoraria.Creditos = this.cargaHorariaLaboratorio.Creditos;
                                this.creditos = this.cargaHorariaLaboratorio.Creditos;

                            }
                        }
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        seleccionMenu(value){
            this.layout = value;
            console.log(this.layout);
        },


        showInfoGeneral(){
            this.isLoading=true;
            this.ss.showCompetencia(this.plan).then(
                (result) => {
                    let response = result.data;
                    if(response == null){
                        this.competencia = {};
                        this.criterios = {};
                        this.contenido = {};
                    }
                    else{
                        this.competencia = response[0];
                        console.log(this.competencia);
                        this.ss.showCriteriosCompetencia(this.competencia).then(
                            (result) => {
                                let response = result;
                                if(response.data == null){
                                    this.criterios = {};
                                    this.contenido = {};
                                }
                                else{
                                    this.criterios = response.data;
                                    console.log('CRITERIOOOOSSSS');
                                    console.log(this.criterios);
                                    this.criterioInf = this.criterios[0];
                                    this.ss.getCriteriosContenido(this.criterios[0]).then(
                                        (result) => {
                                            let response = result;
                                            if(response.data == null){
                                                this.contenido = {};
                                            }
                                            else{
                                                this.contenido = response.data;
                                                console.log('CONTENIDOOOOOO');
                                                console.log(this.contenido);
                                                this.showContenidoTabla();
                                            }
                                        }
                                    ).catch(error => {
                                        console.log(error);
                                        this.isLoading=false;
                                    });
                                }
                            }
                            ).catch(error => {
                                console.log(error);
                                this.isLoading=false;
                            });

                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
            }); 
        },

        showContenidoTabla(){
                this.ss.showContenidoAnalitico(this.contenido[0]).then(
                    (result) => {
                        let response = result;
                        this.contenidosCriterio = response.data;
                        console.log('contenidoAnalitico');
                        console.log(this.contenidosCriterio);
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                }); 
            
        },

        getBibliografias(id){
            this.ss.showBibliografia(id).then(
                (result) => {
                    let response = result;
                    this.bibliografias = response.data;
                    console.log('bibliografia');
                    console.log(response);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            }); 
        
        },

        saveMateriaDocente(){
            this.ss.storeMateriaDocente(this.plan).then(
                (result) => {
                    let response = result;
                    console.log(response.data);
                    this.showMateriaDocente(this.plan);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            }); 
        
        },

        showMateriaDocente(planTrabajo){
            this.ss.showMateriaDocente(planTrabajo).then(
                (result) => {
                    let response = result.data;
                    this.plan = response[0];
                    console.log(this.plan);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        actualizarEstado(){
            this.ss.showMateriaDocente(this.plan).then(
                (result) => {
                    let response = result.data;
                    this.plan = response[0];
                    console.log(this.plan);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },



        verificar(){
            this.ss.verificarReactivosMateriaDocente(this.plan).then(
                (result) => {
                    let response = result;
                    this.reactivosFormativos = response.data
                    console.log(this.reactivosFormativos);

                    let cargaHorariaTotalTeo = 0;
                    let cargaHorariaTotalPrac = 0;
                    let cargaHorariaTotal = 0;
                    if(this.reactivosFormativos[0]){
                        for (let index = 0; index < this.reactivosFormativos.length; index++) {
                            cargaHorariaTotalTeo += this.reactivosFormativos[index].PorTeo;
                            cargaHorariaTotalPrac += this.reactivosFormativos[index].PorPrac;
                        }
                        cargaHorariaTotal = cargaHorariaTotalTeo + cargaHorariaTotalPrac;

                        if((this.criterios.length*100) == cargaHorariaTotal){
                            console.log('porcentaje completo');
                            const swalWithBootstrapButtons = this.$swal.mixin({
                                customClass: {
                                  confirmButton: 'btn-success',
                                  cancelButton: 'btn-danger'
                                },
                                buttonsStyling: true
                              });
                              
                              swalWithBootstrapButtons.fire({
                                title: 'Esta seguro de tramitar el plan de trabajo actual ?',
                                text: "Esta accion es irreversible !",
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonText: 'Si, Subir !',
                                cancelButtonText: 'No, Cancelar !',
                                reverseButtons: true
                              }).then((result) => {
                                if (result.isConfirmed) {
                                    this.plan.estado = 1;
                                    this.saveMateriaDocente();
                                  swalWithBootstrapButtons.fire(
                                    'Tramitado !',
                                    'El plan de trabajo sera evaluado y posteriormente aprobado por el jefe de carrera correspondiente.',
                                    'success'
                                  );
                                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                                  swalWithBootstrapButtons.fire(
                                    'Cancelado',
                                    'Plan de trabajo No tramitado.',
                                    'error'
                                  );
                                }
                              });
                        }

                        if((this.criterios.length*100) < cargaHorariaTotal){
                            console.log('porcentaje superado');
                            this.$swal.fire(
                                'Porcentaje Superado',
                                'Revisar el porcentaje total de los Criterios de Desemepeño',
                                'warning'
                            )
                        }

                        if((this.criterios.length*100) > cargaHorariaTotal){
                            console.log('porcentaje incompleto');
                            this.$swal.fire(
                                'Porcentaje Disponible',
                                'Revisar el porcentaje total de los Criterios de Desemepeño',
                                'warning'
                            )
                        }
                    }else{
                        this.$swal.fire(
                            'Reactivos no creados',
                            'Reactivos formativos no creados todavia',
                            'warning'
                        )
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        getReactivosFormativos(item){
            this.ss.listReactivosFormativos(item).then(
                (result) => {
                    let response = result;
                    this.reactivosDetalle = response.data.data;
                    console.log('REATIVOS DETALLE');
                    console.log(this.reactivosDetalle);
                    this.calcularPorcentajes(this.reactivosDetalle);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        savePlanTrabajoEstado(estado){

            if(this.plan.PlanTrabajo == 0 || this.plan.PlanTrabajo == null){
                this.$swal.fire({
                    title: "Advertencia!",
                    text: "El Plan de Trabajo no fue entregado por el docente!.",
                    icon: "warning"
                  });
                  return;
            }
    
            this.plan.estado = estado;
            this.ss.storeMateriaDocente(this.plan).then(
                (result) => {
                    let response = result;
                    console.log(response.data);
                    if(this.plan.estado == 2){
                        this.$swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Plan de Trabajo Aprobado",
                            showConfirmButton: false,
                            timer: 1500
                          });
    
                          this.ss.cargarPlanTrabajo(this.plan).then(
                            (result) => {
                                let response = result;
                                console.log(response.data);
                            }
                        ).catch(error => {
                            console.log(error);
                            this.isLoading=false;
                        });
    
                    }
                    if(this.plan.estado == 3){
                        this.$swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Plan de Trabajo Rechazado",
                            showConfirmButton: false,
                            timer: 1500
                          });
                    }
                    location.reload();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            }); 
        
        },

        calcularPorcentajes(reactivos){
            console.log('calculado');
            this.porcentajePrac=0;
            this.porcentajeTeo=0;
            this.porcentajeTotal=0;
            this.cargaTeo=0;
            this.cargaPrac=0;
            this.cargaTotal=0;
            reactivos.forEach(rec => {
                this.porcentajePrac += rec.PorPrac;
                this.porcentajeTeo += rec.PorTeo;
                this.cargaTeo += rec.HrsTeo;
                this.cargaPrac += rec.HrsPrac;
            });
            this.porcentajeTotal = this.porcentajePrac + this.porcentajeTeo;
            this.cargaTotal = this.cargaTeo + this.cargaPrac;
        },

    },
    components: {
        InfGeneral,
        Esquema,
        Competencias,
        Criterios,
        Resumen,
        Bibliografia
    },
    directives: {
        'tooltip': VBTooltip
      },
    props: {
        plan: { default: '' },
    },
    mounted() {
        this.auth = JSON.parse(localStorage.getItem('usuario'));
        console.log(this.auth);
        this.showCargaHoraria(this.plan.idMateria);
        this.showInfoGeneral();
        this.getBibliografias(this.plan.idMateria);
        this.getParcial();
        this.url = process.env.VUE_APP_MAIN_SERVICE+'storage/documents/';
        console.log(this.url);
        this.selectCriterio('datos');
    } 
};