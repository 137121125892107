import MainService from "@/services/MainService.js";
import InfGeneral from "@/components/layout/PlanTrabajo/InfGeneral/InfGeneral.vue";
import Esquema from "@/components/layout/PlanTrabajo/Esquema.vue";
import Competencias from "@/components/layout/PlanTrabajo/Competencias.vue";
import Resumen from "@/components/layout/PlanTrabajo/Resumen.vue";
import Bibliografia from "@/components/layout/PlanTrabajo/Bibliografia.vue";

window.$ = window.jQuery = require("jquery");

export default {
    name: "CriterioaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CriterioaPage",
            ss: ss,
            competencia: {},
            criterios: {},
            contenido: {},
            efectivo: {},
            efectivoExamen: {},
            AddEfectivo: {},
            showEfectivo: {},
            asistenciadetalle: {},
            calificacionReactivos: {},
            evaluacionContinuaItem: {},
            evaluacionContinua: {},
            examenParcial: [],
            examenParcialCriterio: [],
            examenNotaParcial: {},
            examenParcialitem: {},
            fechaActual: '',
            fechaClase: '',
            fechaAsistencia: '',
            listaAlumnosAsistencia: {},
            notasReactivo: [],
            criterioDesempenioEvaluacionContinua: [],
            evaluacionParcial: [],
            alumnoSelect: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            especialidad: '',
            criterioSelect: null,
            criterioSelect2: {},
            evaluacionContinua2:{},
            reactivoReactivo:[],
            efectivoReactivo:[],
            concluido:false
        };
    },
    methods: {
        showAsistencia() {
            let fecha = new Date();

            let año = fecha.getFullYear();
            let mes = fecha.getMonth() + 1;
            let dia = fecha.getDate();

            if (mes < 10) {
                mes = '0' + mes;
            }
            if (dia < 10) {
                dia = '0' + dia;
            }

            const fechaActualTexto = `${año}-${mes}-${dia}`;

            this.fechaActual = fechaActualTexto;

            this.ss.getEfectivoMateria(this.cuadernoInf).then(
                (result) => {
                    let response = result;
                    this.efectivo = response.data;
                    console.log(this.efectivo);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });

            this.getAsistenciaDetalle()
        },

        getEstadoSemestre(){
            this.cuadernoInf.IdParcial = 1;
            this.ss.getEstadoSemestre(this.cuadernoInf).then(
                (result) => {
                    let response = result.data;
                    this.parcialconcluido = response.data.parcialconcluido;
                    console.log(this.parcialconcluido);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showResumen(item){
            item.idParcial=1;
            this.ss.listIndividualExamenParcial(item).then(
                (result) => {
                    let response = result;
                    this.alumnoSelect=item;
                    this.examenParcial = response.data.data;
                    this.examenParcialCriterio = response.data.data2; 
                    console.log(response);
                    this.$refs['view-Resumen'].show();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        showResumenCriterio(item){
            item.idParcial=1;
            this.ss.showEvaluacionContinua(item).then(
                (result) => {
                    let response = result;
                    this.alumnoSelect=item;
                    this.examenParcialCriterio = response.data.data;
                    console.log(this.examenParcialCriterio);
                    this.$refs['view-Resumen-criterio'].show();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        selectCriterioDesempeño(){
            this.criterioSelect2 = {};
            this.evaluacionContinua={};
            this.efectivoReactivo=[];
            this.reactivoReactivo=[];
            this.criterioSelect2 = this.criterioDesempenioEvaluacionContinua.find(item => item.id === this.criterioSelect);

            this.criterioSelect2.idCurso = this.efectivo[0].idCurso;
            this.criterioSelect2.idDocente = this.efectivo[0].idDocente;
            this.criterioSelect2.Dicta = this.efectivo[0].Dicta;

            this.criterioSelect2.idEfectivo = this.efectivo[0].id;
            this.criterioSelect2.idParcial = 1;
            this.criterioSelect2.tamaño = this.criterioDesempenioEvaluacionContinua.length;

            this.ss.sincronizarExameParcial(this.criterioSelect2).then(
                (result) => {
                    let response = result;
                    this.evaluacionContinua2 = response.data;

                    this.ss.getEfectivoReactivos(this.evaluacionContinua2).then(
                        (result) => {
                            let response = result;
                            this.efectivoReactivo = response.data;

                            this.efectivoReactivo.sort((a, b) => {
                                const nombreA = a.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                                const nombreB = b.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                                if (nombreA < nombreB) {
                                    return -1;
                                }
                                if (nombreA > nombreB) {
                                    return 1;
                                }
                                return 0; // nombres iguales
                            });

                            console.log(this.efectivoReactivo);
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    });


                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        saveCalificaciones(){
            console.log(this.efectivoReactivo);
            this.efectivoReactivo[0].idParcial=1;
            this.ss.storeGlobalCalificacionesRF(this.efectivoReactivo).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.$bvToast.toast(
                        'Guardado Exitoso!',
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )
                 }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },


        async showCalificarAlumno(item) {
            this.criterioDesempenioEvaluacionContinua = [];
            this.notasReactivo=[];
            item.idParcial =1;
            await this.ss.listCalificacionReactivosFormativos(item).then(
                (result) => {

                    let response = result;
                    this.notasReactivo = response.data;

                    const uniqueIds = new Set();
                    this.notasReactivo.forEach(react => {
                        uniqueIds.add(react.MateriaCriterioDesenmpeno_id);
                    });

                    // Convertir el conjunto en un array
                    const uniqueIdArray = Array.from(uniqueIds);

                    for (let i = 0; i < this.criterios.length; i++) {
                        for (let j = 0; j < uniqueIdArray.length; j++) {
                            if(this.criterios[i]['id'] == uniqueIdArray[j]){
                                this.criterioDesempenioEvaluacionContinua.push(this.criterios[i]);
                            }
                        }
                    }

                }
                
            ).catch(error => {
                console.log("error");

                console.log(error);
                this.isLoading = false;
            });
        },

        getEfectivoParcial(){

            this.cuadernoInf.idParcial = 1;
            this.ss.getEfectivoParcial(this.cuadernoInf).then(
                (result) => {
                    let response = result;
                    this.efectivoExamen = response.data.data;

                    this.efectivoExamen.sort((a, b) => {
                        const nombreA = a.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        const nombreB = b.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        if (nombreA < nombreB) {
                            return -1;
                        }
                        if (nombreA > nombreB) {
                            return 1;
                        }
                        return 0; // nombres iguales
                    });

                    console.log(response);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });

        },

        saveExamenParcial(){

            for (let index = 0; index < this.efectivoExamen.length; index++) {

                if(this.efectivoExamen[index].ExParcial.NotaExamen > 10){
                    this.$swal.fire(
                        'Oops...',
                        'La nota del parcial no pude ser mayor a 10 !.',
                        'warning'
                    )
                    return;
                }
    
                if(this.efectivoExamen[index].ExParcial.NotaExamen <= 0){
                    this.$swal.fire(
                        'Oops...',
                        'La nota del parcial no pude ser menor a 0 !.',
                        'warning'
                    )
                    return;
                }
            }

            const swalPreguntar = this.$swal.mixin({
                customClass: {
                    confirmButton: "btn btn-success",
                    cancelButton: "btn btn-danger"
                },
                buttonsStyling: false
            });

            swalPreguntar.fire({
                title: "Estas Seguro ?",
                text : "Ya no podra realizar cambios en los modulos del primer parcial !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Si !",
                cancelButtonText: "No !",
                reverseButtons: true
            }).then((result => {
                if(result.isConfirmed){
                    swalPreguntar.fire({
                        title: "Guardado !",
                        text: "Registros Guardados Exitosamente !",
                        icon: "success"
                    });

                    this.ss.storeGlobalExamenParcial(this.efectivoExamen).then(
                        (result) => {
                            let response = result;
                            console.log(response);
                            this.$bvToast.toast(
                                response.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'success',
                                    autoHideDelay: 2000
                                }
                            )
                            this.getEstadoSemestre();
                            this.$refs['view-ExamenParcial'].hide();
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    });

                }
                else if (result.dismiss === this.$swal.DismissReason.cancel){
                    swalPreguntar.fire({
                        title: "Cancelado !",
                        text: "cancelado el guardado de registros !",
                        icon: "error"
                    });
                }
            }));
        },

        saveExamenParcialObtativo(){

            console.log(this.efectivoExamen.length);

            for (let index = 0; index < this.efectivoExamen.length; index++) {

                if(this.efectivoExamen[index].ExParcial.NotaExamenObtativo > 10){
                    alert('La nota del Parcial no puede ser mayor a 10 !');
                    return;
                }
    
                if(this.efectivoExamen[index].ExParcial.NotaExamenObtativo <= 0){
                    alert('La nota del Parcial no puede ser menor a 1 !');
                    return;
                }
            }

            this.ss.storeGlobalExamenParcial(this.efectivoExamen).then(
                (result) => {
                    let response = result;
                    console.log(response);
                    this.$bvToast.toast(
                        response.data.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },


        SaveReactivoDetalle(notaReactivo) {
            this.ss.storeReactivosDetalle1(notaReactivo).then(
                (result) => {
                    let response = result;
                    console.log(response);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });


        },

        listExamenParcial(){

            this.cuadernoInf.idParcial = 1;
            this.ss.listExamenParcial(this.cuadernoInf).then(
                (result) => {
                    let response = result;
                    this.examenParcial = response.data.data;

                    this.examenParcial.sort((a, b) => {
                        const nombreA = a.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        const nombreB = b.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        if (nombreA < nombreB) {
                            return -1;
                        }
                        if (nombreA > nombreB) {
                            return 1;
                        }
                        return 0; // nombres iguales
                    });

                    console.log(this.examenParcial);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });

            this.$refs['view-Informe'].show();
        },

        showEditarEvaluacionContinua(item){
            console.log(item);
            this.$refs['view-EditarEvaluacionContinua'].show();
            this.evaluacionContinuaItem = item;
        },

        cancelEditEvaluacionContinua(){
            this.$refs['view-EditarEvaluacionContinua'].hide();
        },

        SaveEvaluacionContinua(){
            console.log(this.evaluacionContinuaItem);
            this.ss.storeGlobalEvaluacionContinua(this.evaluacionContinuaItem).then(
                (result) => {
                    let response = result;
                    console.log(response);
                    //this.evaluacionContinua = response.data.data;
                    this.$bvToast.toast(
                        response.data.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )

                    this.ss.listIndividualExamenParcial(this.examenParcialitem).then(
                        (result) => {
                            let response = result;
                            this.examenParcial = response.data.data;
                            console.log(this.examenParcial);
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    });

                    this.$refs['view-EditarEvaluacionContinua'].hide();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },


        SaveNotaParcial(){
            if(this.calificacionReactivos.CalificacionTeo > 10 || this.calificacionReactivos.CalificacionPrac > 10){
                alert('La nota calificada no puede ser superior a 10 !');
                this.calificacionReactivos.CalificacionTeo = 0;
                this.calificacionReactivos.CalificacionPrac = 0;
                return;
            }
            if(this.calificacionReactivos.CalificacionTeo < 0 || this.calificacionReactivos.CalificacionPrac < 0){
                alert('La nota calificada no puede ser cero !');
                this.calificacionReactivos.CalificacionTeo = 0;
                this.calificacionReactivos.CalificacionPrac = 0;
                return;
            }

            this.ss.storeCalificacionReactivosFormativos(this.calificacionReactivos).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.evaluacionContinua = response.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )
                    this.$refs['view-CalificarNotasReactivosFormativos'].hide();
                 }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        cancleCalificarNota(){
            this.$refs['view-CalificarNotasReactivosFormativos'].hide();
        },

        cancleCalificarExamen(){
            this.$refs['view-ExamenParcial'].hide();
        },

        cancleCalificarExamenObtativo(){
            this.$refs['view-ExamenParcialObtativo'].hide();
        },

        showEditarNota(item){
            console.log(item);
            this.calificacionReactivos = item;
            this.$refs['view-CalificarNotasReactivosFormativos'].show();
        },

        showEvaluacion() {
            this.ss.getEfectivoMateria(this.cuadernoInf).then(
                (result) => {
                    let response = result;
                    this.efectivo = response.data;

                    this.efectivo.sort((a, b) => {
                        const nombreA = a.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        const nombreB = b.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        if (nombreA < nombreB) {
                            return -1;
                        }
                        if (nombreA > nombreB) {
                            return 1;
                        }
                        return 0; // nombres iguales
                    });

                    this.showCalificarAlumno(this.efectivo[0]);
                    this.$refs['view-EvaluacionContinua'].show();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        showCalificarAlumnoParcial(item) {
            item.idParcial = 1;
            console.log(item);
            this.ss.listIndividualExamenParcial(item).then(
                (result) => {
                    let response = result;
                    this.examenNotaParcial = response.data.data[0];
                    console.log(this.examenNotaParcial);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
            this.$refs['view-CalificarParcial'].show();
        },

        showExamen() {
            this.getEfectivoParcial();
            this.getEfectivoParcial();
            this.$refs['view-ExamenParcial'].show();
        },

        showDetallesEvaluacion(){
            this.$refs['view-Detalles-Evaluacion'].show();
        },

        showExamenObtativo(){
            this.getEfectivoParcial();
            this.$refs['view-ExamenParcialObtativo'].show();
        },

        showExamenSegundoTurno(){
            this.getEfectivoParcial();
            this.$refs['view-ExamenSegundoTurno'].show();
        },

        cancelExamenSegundoTurno(){
          this.$refs['view-ExamenSegundoTurno'].hide();
        },

        SaveCriterio() {
            this.$refs['view-Calificar'].hide();
        },

        AddEfectivos() {
            this.$refs['view-AgregarEfectivo'].show();
        },

        async agregarEfectivo(idAlumno) {
            let estado = 0;
            for (let index = 0; index < this.efectivo.length; index++) {
                if (this.efectivo[index].idAlumno == idAlumno) {
                    estado = 1;
                    break;
                }
            }

            if (estado == 1) {
                alert('El estudiante ya esta agregado en el efectivo');
            }
            else {
                //saveAlumnos

                let agregarAlumnoEfectivo = {};

                agregarAlumnoEfectivo.idCurso = this.cuadernoInf.idCurso;
                agregarAlumnoEfectivo.idDocente = this.cuadernoInf.idDocente;
                agregarAlumnoEfectivo.idMateria = this.cuadernoInf.idMateria;
                agregarAlumnoEfectivo.Dicta = this.cuadernoInf.Dicta;
                agregarAlumnoEfectivo.idAlumno = idAlumno;

                console.log(agregarAlumnoEfectivo);

                await this.ss.saveAlumnos(agregarAlumnoEfectivo).then(
                    (result) => {
                        let response = result.data;
                        console.log(response);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 2000
                            }
                        )
                        

                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });

                await this.Efectivo();
                //AgregarEfectivo
                this.$refs['view-AgregarEfectivo'].hide();
                await this.delay(2000);

                this.ss.cargarPlanTrabajo(this.cuadernoInf).then(
                    (result) => {
                        let response = result;
                        console.log(response.data);
                    }
                ).catch(error => {
                    console.log(error);
                    this.isLoading=false;
                });
            }
        },
        async delay(ms) {
            return new Promise(resolve => {
              setTimeout(resolve, ms);
            });
          },
        BuscarEfectivo() {
            this.ss.showAlumnos(this.showEfectivo).then(
                (result) => {
                    let response = result;
                    this.AddEfectivo = response.data;
                    console.log(this.AddEfectivo);
                    this.Refrescar();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        Refrescar() {
            this.$forceUpdate();
        },

        Efectivo() {
            this.ss.getEfectivoMateria(this.cuadernoInf).then(
                (result) => {
                    let response = result;
                    this.efectivo = response.data;
                    this.efectivo.sort((a, b) => {
                        const nombreA = a.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        const nombreB = b.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        if (nombreA < nombreB) {
                            return -1;
                        }
                        if (nombreA > nombreB) {
                            return 1;
                        }
                        return 0; // nombres iguales
                    });
                    this.$refs['view-Efectivo'].show();
                    console.log(this.efectivo);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        saveAsistenciaDetalle() {

            console.log(this.listaAlumnosAsistencia);
            this.listaAlumnosAsistencia[0].tamaño = this.listaAlumnosAsistencia.length;

            this.ss.storeAlumnosAsistencia(this.listaAlumnosAsistencia).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-Asistencia2'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        AlumnoAsiste(index) {
            this.listaAlumnosAsistencia[index].NombreAsistencia = 'Asiste';
            this.listaAlumnosAsistencia[index].DescripcionAsistencia = 'Cuando el estudiante esta presente en clases';
            this.listaAlumnosAsistencia[index].TipoAsistencia_id = 1;
        },

        AlumnoFalta(index) {
            this.listaAlumnosAsistencia[index].NombreAsistencia = 'Falta';
            this.listaAlumnosAsistencia[index].DescripcionAsistencia = 'Cuando el estudiante no esta presente en clases';
            this.listaAlumnosAsistencia[index].TipoAsistencia_id = 2;
        },

        AlumnoPermiso(index) {
            this.listaAlumnosAsistencia[index].NombreAsistencia = 'Permiso';
            this.listaAlumnosAsistencia[index].DescripcionAsistencia = 'Cuando el estudiante tiene permiso para no asistir a clases';
            this.listaAlumnosAsistencia[index].TipoAsistencia_id = 3;
        },

        AlumnoGuardia(index) {
            this.listaAlumnosAsistencia[index].NombreAsistencia = 'Guardia';
            this.listaAlumnosAsistencia[index].DescripcionAsistencia = 'Cuando el estudiante tiene servicio de ';
            this.listaAlumnosAsistencia[index].TipoAsistencia_id = 4;
        },

        cancelAsistencia(){
            this.$refs['view-Asistencia2'].hide();
        },

        AlumnoAsistencia(asistencia) {

            this.fechaAsistencia = asistencia.Fecha;
            this.ss.ListarAlumnoAsistencia(asistencia.id).then(
                (result) => {
                    let response = result.data.data;
                    this.listaAlumnosAsistencia = response;

                    this.listaAlumnosAsistencia.sort((a, b) => {
                        const nombreA = a.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        const nombreB = b.Alumno.toUpperCase(); // Ignorar mayúsculas y minúsculas
                        if (nombreA < nombreB) {
                            return -1;
                        }
                        if (nombreA > nombreB) {
                            return 1;
                        }
                        return 0; // nombres iguales
                    });
            

                    console.log('LISTAR ALUMNOS ASISTENCIA');
                    console.log(this.listaAlumnosAsistencia);
                    this.getTipoClase()

                    //this.$refs['view-Asistencia2'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        getTipoClase() {
            console.log('GET TIPO CLASE');
            this.ss.indexTipoClase().then(
                (result) => {
                    let response = result.data;
                    this.tipoClase = response.data;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        getAsistenciaDetalle() {
            this.cuadernoInf.IdParcial = 1;
            this.ss.HistorialAsistencia(this.cuadernoInf).then(
                (result) => {
                    let response = result.data;
                    this.asistenciadetalle = response.data;
                    if (this.asistenciadetalle) {
                        this.fechaClase = this.asistenciadetalle[0].Fecha
                    }
                    this.$refs['view-Asistencia'].show();

                    console.log(this.fechaClase);
                    console.log(this.fechaActual);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        addAsistencia() {
            /* let fecha = new Date();

            let año = fecha.getFullYear();
            let mes = fecha.getMonth() + 1;
            let dia = fecha.getDate();

            if (mes < 10) {
                mes = '0' + mes;
            }
            if (dia < 10) {
                dia = '0' + dia;
            }

            const fechaActualTexto = `${año}-${mes}-${dia}`;
            this.cuadernoInf.Fecha = fechaActualTexto; */
            this.cuadernoInf.IdParcial = 1;
            console.log(this.cuadernoInf);
            this.ss.createAlumnosAsistencia(this.cuadernoInf).then(
                (result) => {
                    let response = result.data;
                    console.log(response.data);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 2000
                        }
                    )
                    this.getAsistenciaDetalle();
                    this.$refs['view-Asistencia-fecha'].hide();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            }); 
        },

        addAsistenciaFecha(){
            this.$refs['view-Asistencia-fecha'].show();
        },

        cancelAddAsistenciaFecha(){
            this.cuadernoInf.Fecha = '';
            this.$refs['view-Asistencia-fecha'].hide();
        },

        imprimirResumen(){
            this.cuadernoInf.idParcial = 1;
            this.ss.imprimrExamenParcial(this.cuadernoInf).then(
                (response) => {
                    // Crear un blob con el tipo adecuado para PDF
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    
                    // Crear una URL para el blob y abrirla en una nueva ventana
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, '_blank');
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },

        showInfoGeneral() {
            this.isLoading = true;
            this.ss.showCompetencia(this.cuadernoInf).then(
                (result) => {
                    let response = result.data;
                    this.competencia = response;
                    console.log(this.competencia);
                    this.isLoading = false;
                    this.ss.showCriteriosCompetencia(this.competencia[0]).then(
                        (result) => {
                            let response = result;
                            this.criterios = response.data;
                            console.log('CRITERIOOOOSSSS');
                            console.log(this.criterios);
                            this.criterioInf = this.criterios[0];
                            this.ss.getCriteriosContenido(this.criterios[0]).then(
                                (result) => {
                                    let response = result;
                                    this.contenido = response.data;
                                    console.log('CONTENIDOOOOOO');
                                    console.log(this.contenido);

                                    this.contenidoInf = this.contenido[0];
                                    this.criterioSeleccion = this.contenidoInf.id;
                                }
                            ).catch(error => {
                                console.log(error);
                                this.isLoading = false;
                            });
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    });
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error.response.data);
                });
        },
    },
    components: {
        InfGeneral,
        Esquema,
        Competencias,
        Resumen,
        Bibliografia
    },
    props: {
        cuadernoInf: { default: '' },
    },
    mounted() {
        this.showInfoGeneral();
        this.getEstadoSemestre();
        this.especialidad = this.cuadernoInf.Especialidad;
        this.especialidad = this.especialidad.toUpperCase();
    }
};