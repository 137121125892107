var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }else{
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexUsuario() {
        return axios.defaults.baseURL + 'api/Usuario/index';
    }

    showUsuario(id) {
        return axios.get('api/Usuario/show?id=' + id);
    }

    listUsuario() {
        return axios.get('api/Usuario/list');
    }

    storeUsuario(item) {
        return axios.post('api/Usuario/store', item);
    }

    destroyUsuario(item) {
        return axios.post('api/Usuario/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Usuario/changePassword', item);
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //TipoReporte
    showTipoReporte(id) {
        return axios.get('api/TipoReporte/show?id=' + id);
    }

    indexTipoReporte() {
        return axios.defaults.baseURL + 'api/TipoReporte/index';
    }

    listTipoReporte() {
        return axios.get('api/TipoReporte/list');
    }

    storeTipoReporte(item) {
        return axios.post('api/TipoReporte/store', item);
    }

    destroyTipoReporte(item) {
        return axios.post('api/TipoReporte/destroy', item);
    }

    generateTipoReporte(item) {
        return axios.post('api/TipoReporte/generate', item);
    }


    showActitudEstudiante(id) {
        return axios.get('api/ActitudEstudiante/show?id=' + id);
    }

    indexActitudEstudiante() {
        return axios.defaults.baseURL + 'api/ActitudEstudiante/index';
    }

    listActitudEstudiante() {
        return axios.get('api/ActitudEstudiante/list');
    }

    storeActitudEstudiante(item) {
        return axios.post('api/ActitudEstudiante/store', item);
    }

    destroyActitudEstudiante(item) {
        return axios.post('api/ActitudEstudiante/destroy', item);
    }



    showAsignatura(id) {
        return axios.get('api/Asignatura/show?id=' + id);
    }

    indexAsignatura() {
        return axios.defaults.baseURL + 'api/Asignatura/index';
    }

    listAsignatura() {
        return axios.get('api/Asignatura/list');
    }

    storeAsignatura(item) {
        return axios.post('api/Asignatura/store', item);
    }

    destroyAsignatura(item) {
        return axios.post('api/Asignatura/destroy', item);
    }



    showAsignaturaBibliografia(id) {
        return axios.get('api/AsignaturaBibliografia/show?id=' + id);
    }

    indexAsignaturaBibliografia() {
        return axios.defaults.baseURL + 'api/AsignaturaBibliografia/index';
    }

    listAsignaturaBibliografia() {
        return axios.get('api/AsignaturaBibliografia/list');
    }

    storeAsignaturaBibliografia(item) {
        return axios.post('api/AsignaturaBibliografia/store', item);
    }

    destroyAsignaturaBibliografia(item) {
        return axios.post('api/AsignaturaBibliografia/destroy', item);
    }

    


    showEspecialidad(id) {
        return axios.get('api/Especialidad/show?id=' + id);
    }

    indexEspecialidad() {
        return axios.defaults.baseURL + 'api/Especialidad/index';
    }

    listEspecialidad() {
        return axios.get('api/Especialidad/list');
    }

    storeEspecialidad(item) {
        return axios.post('api/Especialidad/store', item);
    }

    destroyEspecialidad(item) {
        return axios.post('api/Especialidad/destroy', item);
    }




    showEspecialidadAsignatura(id) {
        return axios.get('api/EspecialidadAsignatura/show?id=' + id);
    }

    indexEspecialidadAsignatura() {
        return axios.defaults.baseURL + 'api/EspecialidadAsignatura/index';
    }

    listEspecialidadAsignatura() {
        return axios.get('api/EspecialidadAsignatura/list');
    }

    storeEspecialidadAsignatura(item) {
        return axios.post('api/EspecialidadAsignatura/store', item);
    }

    destroyEspecialidadAsignatura(item) {
        return axios.post('api/EspecialidadAsignatura/destroy', item);
    }

    
//HISTORIAL ASISTENCIA
    HistorialAsistencia(item) {
        return axios.post('api/MateriaDocente/HistorialAsistencia', item);
    }

    indexMateriaDocente() {
        return axios.defaults.baseURL + 'api/MateriaDocente/index';
    }

    showMateriaDocente(item){
        return axios.post('api/MateriaDocente/show',item);
    }

    storeMateriaDocente(item){
        return axios.post('api/MateriaDocente/store',item);
    }

    listMateriaDocente() {
        return axios.get('api/MateriaDocente/list');
    }
    verificarReactivosMateriaDocente(item) {
        return axios.post('api/MateriaDocente/verificar', item);
    }

    destroyMateriaDocente(item) {
        return axios.post('api/MateriaDocente/destroy', item);
    }
    MateriaDocenteAsistencia(item) {
        return axios.post('api/MateriaDocente/MateriaDocenteAsistencia', item);
    }


    listarAlumnos() {
        return axios.get('api/MateriaDocente/listarAlumnos');
    }
    saveAlumnos(item) {
        return axios.post('api/MateriaDocente/saveAlumnos', item);
    }
    showReporteAsistecia(id) {
        return axios.get('api/MateriaDocente/showReporteAsistecia?id=' + id);
    }
    ListarAlumnoAsistencia(id) {
        return axios.get('api/MateriaDocente/ListarAlumnoAsistencia?id=' + id);
    }
    createAlumnosAsistencia(item) {
        return axios.post('api/MateriaDocente/createAlumnosAsistencia', item);
    }

    listRequisitos(id) {
        return axios.get('api/MateriaDocente/listRequisito?id='+id);
    }

//EFECTIVO

    showAlumnos(item) {
        return axios.post('api/alumnos/showEfectivo', item);
    }


    showAsistencia123(item) {
        return axios.post('api/MateriaDocente/show', item);
    }

    showAsistenciaDocente(id) {
        return axios.get('api/Asistencia/AsistenciaDocente?id=' + id);
    }

    indexAsistencia() {
        return axios.defaults.baseURL + 'api/Asistencia/index';
    }

    listAsistencia() {
        return axios.get('api/Asistencia/list');
    }

    storeAsistencia(item) {
        return axios.post('api/Asistencia/store', item);
    }

    destroyAsistencia(item) {
        return axios.post('api/Asistencia/destroy', item);
    }

    getEfectivoMateria(item) {
        return axios.post('api/MateriaEfectivo/getEfectivoMateria', item);
    }




    showAlumno(id) {
        return axios.get('api/Alumno/show?id=' + id);
    }

    indexAlumno() {
        return axios.defaults.baseURL + 'api/Alumno/index';
    }

    listAlumno() {
        return axios.get('api/Alumno/list');
    }

    storeAlumno(item) {
        return axios.post('api/Alumno/store', item);
    }

    destroyAlumno(item) {
        return axios.post('api/Alumno/destroy', item);
    }





    showAsistenciaDetalle(id) {
        return axios.get('api/AsistenciaDetalle/show?id=' + id);
    }

    indexAsistenciaDetalle() {
        return axios.defaults.baseURL + 'api/AsistenciaDetalle/index';
    }

    listAsistenciaDetalle() {
        return axios.get('api/AsistenciaDetalle/list');
    }

    storeAsistenciaDetalle(item) {
        return axios.post('api/AsistenciaDetalle/store', item);
    }

    destroyAsistenciaDetalle(item) {
        return axios.post('api/AsistenciaDetalle/destroy', item);
    }


    //MALLA-CURRICULAR

    indexMallaCurricular() {
        return axios.defaults.baseURL + 'api/MateriaDocente/listDisenioCurricular';
    }

    indexPlanTrabajo() {
        return axios.defaults.baseURL + 'api/MateriaDocente/listPlanTrabajo';
    }

    getPlanTrabajo(id) {
        return axios.get('api/MateriaDocente/listPlanTrabajo?id='+id);
    }

    getMallaCurricularPost(item) {
        return axios.post('api/MateriaDocente/listDisenioCurricularPost',item);
    }

    getMallaCurricular(item) {
        return axios.get('api/MateriaDocente/listDisenioCurricular', {
            params: {
                idDocente: item.idDocente,
                Materia: item.materia,
                Sigla: item.sigla
                // Agrega más parámetros si es necesario
            }
        });
    }

    showMallaCurricular(item) {
        return axios.post('api/MateriaDocente/showDisenioCurricular', item);
    }


    listMallaCurricular() {
        return axios.get('api/MallaCurricular/list');
    }

    storeMallaCurricular(item) {
        return axios.post('api/MallaCurricular/store', item);
    }

    destroyMallaCurricular(item) {
        return axios.post('api/MallaCurricular/destroy', item);
    }

    //CUADERNO DE SEGUIMIENTO
    indexCuadernoSeguimiento() {
        return axios.defaults.baseURL + 'api/MateriaDocente/listCuadernoSeguimiento';
    }

    showCuaderno(item){
        return axios.post('api/MateriaDocente/showCuadernoSeguimiento', item);
    }

    getCuadernoSeguimiento(id) {
        return axios.get('api/MateriaDocente/listCuadernoSeguimiento?id='+id);
    }

    //MATERIAS
    showMaterias(id) {
        return axios.get('api/Materias/show?id=' + id);
    }

    indexshowMaterias() {
        return axios.defaults.baseURL + 'api/Materias/index';
    }

    listshowMaterias() {
        return axios.get('api/Materias/list');
    }

    storeshowMaterias(item) {
        return axios.post('api/Materias/store', item);
    }

    destroyshowMaterias(item) {
        return axios.post('api/Materias/destroy', item);
    }

    updateMateriaSaga(item) {
        return axios.post('api/Materias/updateMateriaSaga', item);
    }





    showAsignacionMateriaEstudiante(id) {
        return axios.get('api/AsignacionMateriaEstudiante/list?id=' + id);
    }

    indexAsignacionMateriaEstudiante() {
        return axios.defaults.baseURL + 'api/AsignacionMateriaEstudiante/index';
    }

    listAsignacionMateriaEstudiante() {
        return axios.get('api/AsignacionMateriaEstudiante/list');
    }

    storeAsignacionMateriaEstudiante(item) {
        return axios.post('api/AsignacionMateriaEstudiante/store', item);
    }

    destroyAsignacionMateriaEstudiante(item) {
        return axios.post('api/AsignacionMateriaEstudiante/destroy', item);
    }
    updateAlumno(item) {
        return axios.post('api/AsignacionMateriaEstudiante/update2', item);
    }

    //ASIGNACION MATERIA ESTUDIANTE
    showMateriasEstudiante(id) {
        return axios.get('api/AsignacionMateriaEstudiante/list?id=' + id);
    }

    //ALUMNOS-ASISTENCIAS
    storeAlumnosAsistencia(item){
        return axios.post('api/MateriaDocente/storeAlumnosAsistencia', item);
    }

    //COMPETENCIAS
    getCompetencias(){
        return axios.get('api/MateriaCompetencia/ListCompetenciasSaga');
    }

    showCompetencia(id){
        return axios.post('api/MateriaCompetencia/showCompetenciaSaga', id);
    }

    storeCompetencia(item){
        return axios.post('api/MateriaCompetencia/storeCompetenciaSaga', item);
    }

    //CRITERIOS COMPETENCIAS
    listCriteriosCompetencias(){
        return axios.get('api/MateriaCriterio/ListTableCriteriosSaga');
    }

    getCriteriosCompetencias(){
        return axios.get('api/MateriaCriterio/ListCriteriosSaga');
    }

    showCriteriosCompetencia(id){
        return axios.post('api/MateriaCriterio/showCriteriosCompetenciaSaga', id);
    }

    storeCriterioCompetencia(item){
        return axios.post('api/MateriaCriterio/storeCriteriosCompetenciaSaga', item);
    }
    deleteCriterioCompetencia(item){
        return axios.post('api/MateriaCriterio/deleteCriteriosCompetenciaSaga', item);
    }
    //CRITERIOS CONTENIDO
    getCriteriosContenido(item){
        return axios.post('api/MateriaCriterio/ListContenidoSaga', item);
    }
    showCriteriosContenido(item){
        return axios.post('api/MateriaCriterio/showContenidoSaga', item);
    }
    showContenidoAnalitico(item){
        return axios.post('api/MateriaCriterio/showContenidoAnaliticoSaga', item);
    }
    showAllCriteriosContenido(item){
        return axios.post('api/MateriaCriterio/showAllContenidoSaga', item);
    }
    getCriteriosContenidoPorCompetencia(item){
        return axios.post('api/MateriaCriterio/getCriteriosContenidoPorCompetencia', item);
    }
    storeCriterioContenido(item){
        return axios.post('api/MateriaCriterio/storeCriteriosContenidoSaga', item);
    }
    storeContenidoAnalitico(item){
        return axios.post('api/MateriaCriterio/storeContenidoAnaliticoSaga', item);
    }
    eliminarCriterioContenido(item){
        return axios.post('api/MateriaCriterio/eliminarContenidoSaga', item);
    }

    //CRITERIOS CONTENIDO ANALITICO
    getContenidoAnalitico(){
        return axios.get('api/MateriaCriterio/ListContenidoAnaliticoSaga');
    }

    //REACTIVOS
    indexReactivos(){
        return axios.get('api/Reactivos/indexReactivosSaga');
    }



    //REACTIVOS DETALLE
    indexReactivosDetalle(){
        return axios.get('api/ReactivosDetalle/indexReactivosDetalleSaga');
    }
    showReactivosDetalle(item){
        return axios.post('api/ReactivosDetalle/showReactivosDetalleSaga', item);
    }
    storeReactivosDetalle(item){
        return axios.post('api/ReactivosDetalle/storeReactivosDetalle', item);
    }
    storeReactivosDetalle1(item){
        return axios.post('api/ReactivosDetalle/storeReactivosDetalle1', item);
    }
    
    getReactivosDetalle(item){
        return axios.post('api/ReactivosDetalle/getReactivosDetalle', item);
    }

    deleteReactivosDetalle(id){
        return axios.get('api/ReactivosDetalle/deleteReactivosDetalleSaga?id='+ id);
    }

    //EVALUACIONES PARCIALES
    showEvaluacionesParciales(item){
        return axios.post('api/EvaluacionesParciales/showEvaluacionesParcialesSaga',item);
    }
    updateNotaExamenEvaluacionesParcialesSaga(item){
        return axios.post('api/EvaluacionesParciales/updateNotaExamenEvaluacionesParcialesSaga',item);
    }

    //TIPO CLASE
    indexTipoClase(){
        return axios.get('api/TipoClase/index');
    }

    // PARCIALES
    listParcial(){
        return axios.get('api/Parciales/list');
    }

    // REPORTES
    getReporte(){
        return axios.get('api/Reportes/PlanTrabajo');
    }

    // REACTIVOS FORMATIVOS
    storeReactivosFormativos(item){
        return axios.post('api/ReactivosFormativos/store', item);
    }
    listReactivosFormativos(item){
        return axios.post('api/ReactivosFormativos/list', item);
    }
    showReactivosFormativos(id){
        return axios.get('api/ReactivosFormativos/show?id='+ id);
    }
    destroyReactivosFormativos(item){
        return axios.post('api/ReactivosFormativos/destroy', item);
    }
    cargarPlanTrabajo(item){
        return axios.post('api/ReactivosFormativos/cargarPlanTrabajo', item);
    }


    // CALIFICACION REACTIVOS FORMATIVOS
    listCalificacionReactivosFormativos(item){
        return axios.post('api/CalificacionRF/list', item);
    }

    getEfectivoReactivos(item){
        return axios.post('api/CalificacionRF/getEfectivoReactivos', item);
    }

    storeCalificacionReactivosFormativos(item){
        return axios.post('api/CalificacionRF/store', item);
    }

    storeGlobalCalificacionesRF(item){
        return axios.post('api/CalificacionRF/storeGlobal', item);
    }

    // EXAMEN PARCIAL

    habilitarExamen2T(item){
        return axios.post('api/ExamenParcial/habilitarExamen2T', item);
    }

    habilitarExamenParcial(item){
        return axios.post('api/ExamenParcial/habilitarParcial', item);
    }

    habilitarExamenOptativo(item){
        return axios.post('api/ExamenParcial/habilitarExamenOptativo', item);
    }

    sincronizarExameParcial(item){
        return axios.post('api/ExamenParcial/sincronizar', item);
    }

    listIndividualExamenParcial(item){
        return axios.post('api/ExamenParcial/listIndividual', item);
    }

    listExamenParcial(item){
        return axios.post('api/ExamenParcial/list', item);
    }

    getEstadoSemestre(item){
        return axios.post('api/ExamenParcial/getEstadoSemestre', item);
    }

    getEfectivoParcial(item){
        return axios.post('api/ExamenParcial/getEfectivoParcial', item);
    }

    getEfectivo2T(item){
        return axios.post('api/ExamenParcial/getEfectivo2T', item);
    }

    storeExamenParcial(item){
        return axios.post('api/ExamenParcial/store', item);
    }

    storeGlobalExamenParcial(item){
        return axios.post('api/ExamenParcial/storeGlobal', item);
    }

    storeGlobalExamen2T(item){
        return axios.post('api/ExamenParcial/storeGlobal2T', item);
    }

    imprimrExamenParcial(item){
        return axios.post('api/ExamenParcial/ImprimirParcial', item, {
            responseType: 'blob'
        });
    }

    imprimirObtativo(item){
        return axios.post('api/ExamenParcial/imprimirObtativo', item, {
            responseType: 'blob'
        });
    }


    imprimirExamenSegundoTurno(item){
        return axios.post('api/ExamenParcial/imprimirExamenSegundoTurno', item, {
            responseType: 'blob'
        });
    }

    imprimirResumen(item){
        return axios.post('api/ExamenParcial/imprimriResumen', item, {
            responseType: 'blob'
        });
    }


    // EVALUACION CONTINUA
    storeEvaluacionContinua(item){
        return axios.post('api/EvaluacionContinua/store', item);
    }

    showEvaluacionContinua(item){
        return axios.post('api/EvaluacionContinua/show', item);
    }

    storeGlobalEvaluacionContinua(item){
        return axios.post('api/EvaluacionContinua/storeGlobal', item);
    }

     // JEFE DE CARRERA
     indexJefeCarrera(){
        return axios.defaults.baseURL + 'api/JefeCarrera/index';
    }

    //CARGA HORAIA
    shorCargaHoraria(id){
        return axios.get('api/MateriasCargaHoraria/show?id='+ id);
    }

    //BIBLIOGRAFIA
    showBibliografia(id){
        return axios.get('api/MateriaDocente/showBibliografia?idMateria='+ id);
    }

    //JEFE DE CARRERA

    indexJefeCarreraPlanesTrabajo(){
        return axios.defaults.baseURL + 'api/MateriaDocente/indexJefeCarrera';
    }

}
