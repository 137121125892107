import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "RolPage",
    data() {
        let ss = new MainService();
        return {
            msg: "RolPage",
            ss: ss,
            ajax: {
                "url": ss.indexRol(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Rol', name: 'Rol', title: 'Unidad Académica' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            array: [],
            rol: {},
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        addArray() {
            let unidad;
            let codigo="";
            let codigoActivo; 

            let cadena = this.rol.Num; 

            unidad = cadena[0];


            
            for (let index = 2; index < cadena.length; index++) {
                codigo += cadena[index];
            }

            codigo = parseInt(codigo);
            codigo++;
            codigoActivo = unidad + '-'+codigo;
            this.array.push(codigoActivo); 
   
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
            this.$router.push('/Login');
        }
    }
};
