var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"height":"auto","background-color":"#F7F7F7","width":"100%","word-wrap":"break-word","overflow-x":"auto","display":"block"}},[_c('div',{staticClass:"card-body"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 p-3",staticStyle:{"border-right":"2px solid black"},attrs:{"id":"borderCotrole"}},[_vm._m(1),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-sm-6"},[_vm._v(" "+_vm._s(_vm.planTrabajoObject.Materia)+" ")])]),_vm._m(3),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-sm-6"},[_vm._v(" "+_vm._s(_vm.planTrabajoObject.Sigla)+" ")])]),_vm._m(5),_vm._m(6)]),_vm._m(7)])]),_vm._m(8),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_vm._v(" "+_vm._s(_vm.planTrabajoObject.Justificacion ? _vm.planTrabajoObject.Justificacion : 'Sin justificacion.')+" ")])])]),_vm._m(9),_vm._m(10)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"tituloSeccion"},[_vm._v(" I. DATOS REFERENCIALES DE LA ASIGNATURA. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row",staticStyle:{"border-bottom":"2px solid black"}},[_c('h4',[_c('strong',[_vm._v(" Datos Generales")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-sm-6"},[_c('strong',[_vm._v("Asignatura")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('strong',[_vm._v("Area de Conocimiento ")])]),_c('div',{staticClass:"col-sm-6"},[_vm._v(" .... ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-sm-6"},[_c('strong',[_vm._v("Sigla ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('strong',[_vm._v("Ciclo Formativo ")])]),_c('div',{staticClass:"col-sm-6"},[_vm._v(" .... ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('strong',[_vm._v("Grado ")])]),_c('div',{staticClass:"col-sm-6"},[_vm._v(" .... ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-lg-6 p-3"},[_c('div',{staticClass:"row",staticStyle:{"border-bottom":"2px solid black"}},[_c('h4',[_c('strong',[_vm._v(" Carga Horaria")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-responsive",staticStyle:{"border":"2px solid rgba(128, 128, 128, 0.521)","border-radius":"5px"}},[_c('table',{staticClass:"table table-bordered table-striped"},[_c('thead',[_c('tr',[_c('th',[_vm._v("AP TEO")]),_c('th',[_vm._v("AP PRACT")]),_c('th',[_vm._v("AP PRACT LAB")]),_c('th',[_vm._v("AP TOTAL SEMANAL")]),_c('th',[_vm._v("TOTAL SEMESTRAL")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("0")]),_c('td',[_vm._v("0")]),_c('td',[_vm._v("0")]),_c('td',[_vm._v("0")]),_c('td',[_vm._v("0")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"tituloSeccion"},[_vm._v(" II. JUSTIFICACION DE LA ASIGNATURA PARA EL CUMPLIMIENTO DEL PERFIL PROFESIONAL. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"tituloSeccion"},[_vm._v(" III. DESCRIPCION DE LA ASIGNATURA. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('p',[_vm._v("Sin descripcion.")])])])])
}]

export { render, staticRenderFns }